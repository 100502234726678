<div class="sponsor-list">
  <div *ngFor="let sponsor of sponsors" class="sponsor-item">
    <div class="sponsor-content">
      <a class="img-a" [href]="sponsor.website" target="_blank">
        <img [src]="sponsor.logoUrl" alt="{{ sponsor.name }} Logo" class="sponsor-image" />
      </a>

      <div class="sponsor-statement">
        <p><a class="name" [href]="sponsor.website" target="_blank">{{sponsor.name}}</a>{{sponsor.missionStatement}}</p>
      </div>


    </div>
  </div>
</div>
