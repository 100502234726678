import { EventEmitter, Injectable, inject } from '@angular/core';
import {
  Database,
  connectDatabaseEmulator,
  list,
  objectVal,
  onValue,
  ref,
} from '@angular/fire/database';
import {
  Functions,
  httpsCallable,
  connectFunctionsEmulator,
} from '@angular/fire/functions';

import { Game, GameSettings, GameStatus, Success } from 'shared';
@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  database: Database = inject(Database);
  functions: Functions = inject(Functions);

  userId: string = '';

  public debug = false;
  constructor() {
    // connectFirestoreEmulator(this.firestore, 'localhost', 8080);
    this.functions.region = 'europe-west1';
    //

    if (this.debug) {
      console.log('CONNECTING EMULATORS');
      connectFunctionsEmulator(this.functions, 'localhost', 5001);
      connectDatabaseEmulator(this.database, 'localhost', 9000);
    }
  }

  handleResult(name: string, a: any) {
    if (a && a.success) {
      return a.data;
    } else if (a && a.data) {
      console.log('error: ' + name, a, typeof a);
      return false;
    }
    return a;
  }

  async createGame(displayName: string, gameSettings: GameSettings) {
    if (!displayName) {
      alert('Please choose a username');
      return;
    }
    return this.handleResult(
      'Create Game',
      await httpsCallable(
        this.functions,
        'makeGame'
      )({ userId: this.userId, displayName: displayName, gameSettings })
        .then((a) => a.data)
        .catch((e) => e)
    );
  }

  async joinGame(gameId: string, displayName: string, password: string = '') {
    if (!displayName || !gameId) {
      alert('Please choose a username and enter a game id');
      return;
    }
    return this.handleResult(
      'Join Game',
      await httpsCallable(
        this.functions,
        'joinGame'
      )({
        userId: this.userId,
        gameId: gameId,
        displayName: displayName,
        password,
      })
        .then((a) => a.data)
        .catch((e) => e)
    );
  }

  async rollDice(gameId: string, debug: number | null = null) {
    var obj: any = { userId: this.userId, gameId };
    if (debug) {
      obj.debugRoll = debug;
    }
    return this.handleResult(
      'Roll Dice',
      await httpsCallable(
        this.functions,
        'rollDice'
      )(obj)
        .then((a) => a.data)
        .catch((e) => e)
    );
  }

  async startGame(gameId: string) {
    return this.handleResult(
      'Start Game',
      await httpsCallable(
        this.functions,
        'startGame'
      )({ userId: this.userId, gameId })
        .then((a) => a.data)
        .catch((e) => e)
    );
  }

  async getCards() {
    return this.handleResult(
      'Get Cards',
      await httpsCallable(
        this.functions,
        'getCards'
      )({})
        .then((a) => a.data)
        .catch((e) => e)
    );
  }

  async actionDone(gameId: string, actionType: string) {
    return this.handleResult(
      'Action Done',
      await httpsCallable(
        this.functions,
        'actionDone'
      )({ userId: this.userId, gameId, actionType })
        .then((a) => a.data)
        .catch((e) => e)
    );
  }

  async triggerClean() {
    return this.handleResult(
      'Trigger Clean',
      await httpsCallable(
        this.functions,
        'cleanGames'
      )({})
        .then((a) => a.data)
        .catch((e) => e)
    );
  }

  private gamesListener = new EventEmitter<GameStatus[]>();
  followGames() {
    return this.gamesListener.asObservable();
  }

  getGameStatistics(gameId: string) {
    return new Promise((res, rej) => {
      onValue(ref(this.database, 'gameStatistics/' + gameId), (snapshot) => {
        return res(snapshot.val());
      });
    });
  }

  async sendEmail(name: string, email: string, message: string) {
    if (!name) {
      alert('Please enter your name');
      return;
    }

    if (!email || !email.includes('@')) {
      alert('Please enter your email');
      return;
    }

    if (!message) {
      alert('Please enter your message');
      return;
    }

    return this.handleResult(
      'sendEmail',
      await httpsCallable(
        this.functions,
        'sendEmail'
      )({ name, email, message })
        .then((a) => a.data)
        .catch((e) => e)
    );
  }

  getGames() {
    return onValue(
      ref(this.database, 'gameInfo/'),
      (snapshot) => {
        try {
          var data = Object.values(snapshot.val());
          this.gamesListener.emit(
            data.map((a: any) => {
              return a as GameStatus;
            })
          );
        } catch (e) {
          this.gamesListener.emit([]);
        }
      },
      (error) => {
        console.error(error);
        this.gamesListener.emit([]);
      }
    );
  }

  updatePlayerName(gameId: string, userId: string, displayName: string) {
    return this.handleResult(
      'updatePlayerName',
      httpsCallable(
        this.functions,
        'updatePlayerName'
      )({ gameId, userId, displayName })
        .then((a) => a.data)
        .catch((e) => e)
    );
  }
}
function connectFirestoreEmulator(firestore: any, arg1: string, arg2: number) {
  throw new Error('Function not implemented.');
}
