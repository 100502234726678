import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GameComponent } from './Pages/game/game.component';
import { LobbyComponent } from './Pages/lobby/lobby.component';
import { AboutComponent } from './Pages/about/about.component';
import { HowToComponent } from './Pages/how-to/how-to.component';
import { SponsorsComponent } from './Pages/sponsors/sponsors.component';
import { CardsComponent } from './Pages/cards/cards.component';
import { ContactComponent } from './Pages/contact/contact.component';
const lobbyRoutes: Routes = [
  { path: 'about', component: AboutComponent },
  { path: 'howto', component: HowToComponent },
  { path: 'sponsor', component: SponsorsComponent },
  { path: 'cards', component: CardsComponent },
  { path: 'contact', component: ContactComponent },
  { path: '', redirectTo: 'about', pathMatch: 'full' }, // Redirect to "about" by default
];

const routes: Routes = [
  { path: 'game', component: GameComponent },
  {
    path: 'lobby',
    component: LobbyComponent,
    children: lobbyRoutes, // Child routes for "lobby"
  },
  { path: '', redirectTo: 'lobby', pathMatch: 'full' }, // Redirect to "lobby" by default
  { path: '**', component: LobbyComponent }, // Catch-all route for "lobby"
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
