<div class="flex-row" id="row-with-scroll">
    <div class="card-category" *ngFor="let cat of categories | keyvalue" [class]="'card-category ' + (cat.key == currentCategory ? 'selected' : '')" (click)="nextCategory(cat.key)">
        {{ cat.key }}
    </div>
</div>
<br>
<label style="font-weight: 400; font-style: italic;">Slide the bar left and right to see the categories and click a card to flick through the deck</label>
<div style="flex-grow: 1;"></div>
<div class="cards-container" (click)="nextCard($event)" *ngIf="cards && categories[currentCategory]" id="cards-container">
    <div class="deck" id="lobby-deck">
        <div *ngFor="let card of categories[currentCategory] " [class]="'card card-animation ' + position">
            <div class="card-inner">
                <img class="card-back full-card-back" src="./assets/card-back.png">
                <div class="card-front">
                    <img [src]="'assets/cards/' + card.asset + '.png'">
                </div>
            </div>
        </div>
    </div>

</div>

<div style="flex-grow: 1;"></div>
