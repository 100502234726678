import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SoundsService {
  cards: HTMLAudioElement[] = [new Audio('assets/audio/card.wav')];
  dice: HTMLAudioElement[] = [
    new Audio('assets/audio/dice.mp3'),
    new Audio('assets/audio/dice-2.mp3'),
    new Audio('assets/audio/dice-3.mp3'),
    new Audio('assets/audio/dice-4.mp3'),
    new Audio('assets/audio/dice-5.mp3'),
  ];
  gameOvers: HTMLAudioElement[] = [new Audio('assets/audio/gameover.wav')];
  leaves = [new Audio('assets/audio/leaf.mp3')];
  slide = [new Audio('assets/audio/slide.mp3')];
  turn = [new Audio('assets/audio/turn.wav')];
  victory = [new Audio('assets/audio/victory.wav')];
  move = [new Audio('assets/audio/move.mp3')];

  cheer = [new Audio('assets/audio/applause7.wav')];
  boo = [new Audio('assets/audio/crowd-groan.wav')];
  promises: Promise<boolean>[] = [];

  constructor() {
    this.loadAudio();
  }

  playCard() {
    //play a random card
    this.cards[Math.floor(Math.random() * this.cards.length)].play();
  }

  playDice() {
    //play a random dice
    this.dice[Math.floor(Math.random() * this.dice.length)].play();
  }

  playSlide() {
    this.slide[0].play();
  }

  playTurn() {
    this.turn[0].play();
  }

  playLeaves() {
    this.leaves[0].play();
  }

  playVictory() {
    this.victory[0].play();
  }

  playGameOver() {
    this.gameOvers[0].play();
  }

  playMove() {
    this.move[0].play();
  }

  playCheer() {
    console.log('cheering');
    this.cheer[0].volume = 0.5;
    this.cheer[0].play();
  }

  playBoo() {
    console.log('booing');
    this.boo[0].volume = 0.6;
    this.boo[0].play();
  }

  loadAudio(): Promise<boolean[]> {
    this.promises = [];
    this.cards.forEach((card) => {
      this.promises.push(this.loadSound(card));
    });
    this.dice.forEach((dice) => {
      this.promises.push(this.loadSound(dice));
    });
    this.promises.push(this.loadSound(this.gameOvers[0]));
    this.promises.push(this.loadSound(this.leaves[0]));
    this.promises.push(this.loadSound(this.slide[0]));
    this.promises.push(this.loadSound(this.turn[0]));
    this.promises.push(this.loadSound(this.victory[0]));
    this.promises.push(this.loadSound(this.move[0]));
    this.promises.push(this.loadSound(this.cheer[0]));
    this.promises.push(this.loadSound(this.boo[0]));

    return Promise.all(this.promises);
  }

  loadSound(audio: HTMLAudioElement): Promise<boolean> {
    return new Promise((res, rej) => {
      audio.load();
      audio.onloadeddata = () => {
        res(true);
      };
    });
  }
}
