import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sponsors',
  templateUrl: './sponsors.component.html',
  styleUrls: ['./sponsors.component.scss'],
})
export class SponsorsComponent implements OnInit {
  sponsors: any[] = [
    {
      name: 'Reconnaissance International',
      website: 'https://www.reconnaissance.net',
      logoUrl: 'assets/logo2-cropped.png',
      missionStatement:
        ' is the leading global source of business intelligence on currency, optical and digital document security, personal identification, authentication and brand protection. From our unique knowledge of these highly-specialised and rapidly-changing fields , we offer authoritative information and practical guidance on markets, technologies, trends and strategies through publications, newsletters, conferences, consultancy and management support services.',
      catchPhrase: '',
    },
    {
      name: 'Cash & Payment News',
      website: 'http://cashpaymentnews.com/',
      logoUrl: 'assets/cash-payment-news-colour.png',
      missionStatement:
        ', launched in 2018, is the only news source dedicated specifically to the cash cycle and the role of cash within the payment eco-system. As such, it provides a unique source of information on cash as a payment tool relative to the alternativess.         In terms of cash as a payment option, topics covered include payments regulation, policy and legislation; consumer payment trends, studies and research; cashless or less-cash initiatives and counter initiatives; fraud and security; central bank digital currencies; financial inclusion; and the environment and sustainability.',
      catchPhrase: '',
    },
    {
      name: 'MyCarbon',
      website: 'https://www.mycarbon.co.uk/',
      logoUrl: 'assets/my_carbon.png',
      missionStatement:
        ' are a sustainability consultancy specialising in the calculation, reduction and offsetting of carbon footprints, to support clients on their journey to net-zero. MyCarbon work with clients who share their view on the seriousness of this challenge. They work across all industries, helping SME’s and large corporations mitigate their climate impact. Its approach draws on the scientific and engineering expertise of its team so that they can always create a pragmatic and actionable plan for every business and their specific needs. MyCarbon’s mission is that for every hour MyCarbon and its clients spend working together, they produce the largest positive environmental impact possible.',
      catchPhrase: '',
    },
  ];
  constructor() {}

  ngOnInit(): void {}
}
