import {
  Component,
  Input,
  OnInit,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { Card, Game, GameStatistics, Player } from 'shared';
import { FirebaseService } from '../../firebase.service';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { max } from 'rxjs';

@Component({
  selector: 'app-victory',
  templateUrl: './victory.component.html',
  styleUrls: ['./victory.component.scss'],
})
export class VictoryComponent implements OnInit {
  @Input('game') game?: Game;
  showModal: boolean = false;
  gameStatistics: GameStatistics = new GameStatistics();
  pxPerCell: number = 0;
  categories: { [cat: string]: any } = ({} = {});
  categorieQuantities: { [cat: string]: number } = ({} = {});
  scores: any = {};
  cards: Card[] = [];
  cardIndex: number = -1;
  position = 'center';
  position2 = 'center';
  working: boolean = false;
  shiftSize: number = 300;

  TAG: string = 'VictoryComponent';
  constructor(
    public fb: FirebaseService,
    public ref: NgbActiveModal,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    if (!this.game) return;
    this.fb.getGameStatistics(this.game.id).then((a) => {
      console.log('game statistics', a);
      this.gameStatistics = a as GameStatistics;
      var players = Object.values(this.gameStatistics.players).map((a) => {
        return {
          key: a.player.id,
          value: a.player.displayName,
          quantity: 0,
          turns: a.turns,
          ladders: a.ladders,
          snakes: a.snakes,
          snakeMoves: a.snakeMoves,
          ladderMoves: a.ladderMoves,
        };
      });

      var temp: { [cat: string]: any } = {};
      this.game?.cards.forEach((c) => {
        var d = c as any;
        temp[c.category] = temp[c.category] || { cards: [], players: [] };
        temp[c.category].players = Object.assign(
          [],
          players.map((a) => Object.assign({}, a))
        );
        if (temp[c.category].cards) {
          temp[c.category].cards.push(d);
        } else {
          temp[c.category].cards = [d];
        }
      });

      for (var p of players) {
        var cards = [];
        var cardStats = Object.values(this.gameStatistics?.cards || []).filter(
          (a) => a.player == p.key
        );
        for (var c of cardStats) {
          var card = this.game?.cards.find((a) => a.id == c.card);
          if (!card) {
            continue;
          }
          cards.push(card);
          if (p.key == this.fb.userId) {
            this.cards.push(card);
          }

          temp[card.category].players.find((a: any) => a.key == p.key)
            .quantity++;
        }
        this.calculateScores(p, cards);
      }

      this.categories = temp;

      var largestRoll = this.gameStatistics.dice.reduce((prev, curr) => {
        return Math.max(prev, curr.rolls);
      }, 1);
      this.pxPerCell = 145 / largestRoll;
    });

    setTimeout(() => {
      this.showModal = true;
      setTimeout(() => {
        this.initCards();
      }, 50);
      setTimeout(() => {
        this.nextCard(0, true);
      }, 3000);
    }, 1000);
  }

  log(message: string, ...optionalParams: any[]) {
    if (this.fb.debug) {
      console.log(this.TAG, message, optionalParams);
    }
  }

  calculateScores(player: any, cards: any[]) {
    console.log(player, cards);
    var maxScore = Math.max(1, cards.length * 3);
    var positiveMoves = cards
      .filter((a) => a.moves > 0)
      .reduce((prev, curr) => {
        return prev + curr.moves;
      }, 0);
    var negativeMoves = cards
      .filter((a) => a.moves < 0)
      .reduce((prev, curr) => {
        return prev + curr.moves;
      }, 0);
    console.log(
      'max score: ' +
        maxScore +
        ', positiveMoves, ' +
        positiveMoves +
        ', negativeMoves ' +
        negativeMoves
    );

    var snakeMoves = Math.abs(player.snakeMoves);
    var ladderMoves = player.ladderMoves;

    var positive = cards.filter((a) => a.moves > 0).length + player.ladders;
    var negative = cards.filter((a) => a.moves < 0).length + player.snakes;

    var score =
      (positive - negative) /
      Math.max(0.0001, player.snakes + player.ladders + cards.length);

    this.scores[player.key] = Math.max(
      -100,
      Math.min(100, Math.round(score * 100))
    );
  }

  close() {
    this.ref.close();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.initCards();
    }, 50);
  }
  ngOnChanges(changes: SimpleChanges): void {}

  async initCards() {
    var cardsContainer = document.getElementById('victory-deck');
    console.log('initcards1');
    if (!cardsContainer) return;
    console.log('initcards2');

    //so cards is a deck of cards, with z-index so that top card is index 0 and bottom card is index cards.length - 1, cardIndex is the current front card top of deck,
    //any cards before cardindex should be on left, any after should be on right by shifting their absolute left position by 260px
    var sizeOfRightCards = this.cardIndex;
    var stepSize = 0.2;
    for (var i = 0; i <= this.cardIndex; i++) {
      var card = cardsContainer.children[i] as HTMLDivElement;
      card.style.left = this.shiftSize - 250 + 'px';

      var step = this.cardIndex - i;
      var z = 501 - step;
      var rotateZ = 0 - step * stepSize;
      var rotateY = 0;
      var rotateX = 0;

      card.style.zIndex = `${z}`;
      this.renderer.setStyle(
        card,
        'transform',
        `rotateX(${rotateX}deg) rotateY(${rotateY}deg) rotateZ(${rotateZ}deg)`
      );
    }

    var stepSize = 0.2;
    for (var i = this.cardIndex + 1; i < cardsContainer.children.length; i++) {
      var card = cardsContainer.children[i] as HTMLDivElement;
      card.style.left = -this.shiftSize + 'px';

      var step = (this.cardIndex + 1 - i) * -1;
      var z = 500 - step - (this.cardIndex + 1);
      var rotateZ = 0 - step * stepSize;
      var rotateY = 0;
      var rotateX = 0;

      card.style.zIndex = `${z}`;
      this.renderer.setStyle(
        card,
        'transform',
        `rotateX(${rotateX}deg) rotateY(${rotateY}deg) rotateZ(${rotateZ}deg)`
      );
    }
  }

  async nextCard(event: any, firstClick = false) {
    if (this.working) return;
    var deck = document.getElementById('victory-deck');
    if (!deck) {
      return;
    }
    //check if clicked on left or right of deck view
    var deckCoords = deck.getBoundingClientRect();

    var left =
      firstClick || event.clientX < deckCoords.left + deckCoords.width / 2;

    if (!left) {
      //flip card backwards
      if (this.cardIndex < 0) {
        this.cardIndex = -1;
        this.initCards();
        return;
      }
      this.working = true;
      await this.flipCardBackwards(deck);
      this.working = false;
      this.initCards();
      return;
    } else {
    }

    if (this.cardIndex >= deck.children.length - 1) {
      this.cardIndex = deck.children.length - 1;
      this.initCards();
      return;
    }

    this.working = true;
    await this.flipCardForwards(deck);
    this.working = false;

    this.initCards();
  }

  async flipCardForwards(deck?: HTMLElement) {
    this.cardIndex++;
    //get card at that index

    if (!deck) return;
    var card = deck.children[this.cardIndex] as HTMLDivElement;
    if (!card) return;

    //flip the card to the left
    card.style.left = this.shiftSize - 250 + 'px';
    card.style.zIndex = '600';
    (card.children[0] as HTMLDivElement).style.transform = 'rotateY(180deg)';
    await this.waitN(400);
  }

  async flipCardBackwards(deck?: HTMLElement) {
    this.cardIndex--;
    //get card at that index

    if (!deck) return;
    var card = deck.children[this.cardIndex + 1] as HTMLDivElement;
    if (!card) return;

    //flip the card to the left
    card.style.left = -this.shiftSize + 'px';
    card.style.zIndex = '600';
    (card.children[0] as HTMLDivElement).style.transform = 'rotateY(0deg)';
    await this.waitN(400);
  }

  async waitN(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  playagain() {}

  share() {}
}
