<div class="column side" style="color: white;" [style.height.px]="((boxHeight + 10) * 9)">
  <img src="assets/logo.svg" style="width: 100%; object-fit: contain; max-height: 20%;">
  <div style="flex-grow: 1;"></div>
  <div class="drawDiscard">
    <div class="deck">
      <div *ngIf="activeCards" class="cards" id="activeCards" style="">

        <img class="card-back card full-card-back" src="./assets/cards/card-01.png">
      </div>

      <div *ngIf="!activeCards" class="cards">
        <div class="card card-front" style="background-color: #652d92; text-align: center;"> PICK UP PILE</div>
      </div>
    </div>

    <div class="deck  zoomOnHover">
      <div *ngIf="burntCards && burntCards[0]" class="cards " id="burntCards">
        <div [class]="'card card-front'">
          <img [src]="'assets/cards/' + burntCards[0].asset + '.png'">


        </div>
      </div>

      <div *ngIf="game && (!burntCards.length || !burntCards[0])" class="cards">
        <div class="card card-front" style="background-color: black; font-size: x-large; text-align: center;"> DISCARD
          PILE
        </div>

      </div>
    </div>


  </div>
  <div style="flex-grow: 1;"></div>


  <button class="die" (click)="roll()"
          [class.canRoll]="!(rollTimeout || gameUpdateTimeout) && game && game.actions && game.actions[firebase.userId] &&  game.actions[firebase.userId].type && game.actions[firebase.userId].type == 'roll'"
          [disabled]=" rollTimeout || !gameUpdateTimeout || (game?.host == firebase.userId && game?.status?.status == 'waiting') ? false : (game && game.actions && game.actions[firebase.userId] && game.actions[firebase.userId].type == 'roll' ? false : true)">
    <div class="dice-container" style="height: 200px;" id="dice1Container">
      <app-dice [rollSpeed]="2500" [roll]="rollObserver" id="dice1"></app-dice>
    </div>
    <div class="dice-container" style="height: 200px;" id="dice2Container">
      <app-dice [rollSpeed]="2500" [roll]="rollObserver2" id="dice2"></app-dice>
    </div>
  </button>


  <div class="inner players-actions">
    <div class="button-row">
      <button class="icon-button tooltip" style="opacity: 0%;"><span class="tooltiptext">The
          player named below is the
          current active player it is their turn to roll or complete their actions</span><i
           class="fas fa-info"></i></button>
      <h1 style="font-size: x-large;">Active Player</h1>
      <button class="icon-button" (click)="openSettings()">
        <i [class]="'fas fa-cog ' + (settingsClosed ? 'spin' : '')"></i>
      </button>
    </div>

    <div class="player-row" *ngIf="activePlayer">
      <div class="player" [style.box-shadow]="'1px 1px 1px 1px ' + activePlayer.shadowColor"
           [style.backgroundColor]="activePlayer.color">
        <span [style.color]="activePlayer.textColor">{{activePlayer.displayName | shorternName}}</span>
      </div>
      <span>{{activePlayer.displayName}}</span>
      <i [style.opacity]="(game && game.actions && game.actions[activePlayer.id] && game.actions[activePlayer.id].type == 'roll') ? '1' : '0'"
         class="fas fa-dice"></i>
    </div>
  </div>

</div>

<div class="column larger" style="position: relative;">

  <div class="table" #gameTable id="gameTable" [style.height.px]="((boxHeight + 12) * 9)"
       [style.width.px]="((boxHeight + 12) * 9)">
    <div class="table-row" *ngFor="let row of game?.table; let c = index">
      <div [class]="'table-cell ' + cell.color + ' cell-' + c + '-' + r" [id]="'cell-' + c + '-' + r"
           *ngFor="let cell of row; let r = index" [style.width.px]="boxHeight" [style.height.px]="boxHeight">
        <div class="table-cell-number">{{cell.id}}</div>
        <div [class]="cell.classes" [attr.data-tooltip]="cell.text">{{cell.text}}
          <div class="icon"></div>
        </div>

      </div>
    </div>
  </div>
  <canvas style="pointer-events: none; position: absolute; " [style.height.px]="((boxHeight + 12) * 9)"
          [style.width.px]="((boxHeight + 12) * 9)" id="gameCanvas" #gameCanvas class="table"></canvas>
  <img class="table" src="./assets/vines/PNG/please.png" [style.height.px]="((boxHeight + 12) * 9)"
       [style.width.px]="((boxHeight + 12) * 9)" style="pointer-events: none; position: absolute;">

  <div *ngFor="let piece of pieces" style="position: absolute; z-index: 60;" (transitionstart)="startedMoving()"
       [style.left]="piece.left" [style.top]="piece.top" class="player"
       [style.box-shadow]="'1px 1px 1px 1px ' + piece.player.shadowColor" [style.backgroundColor]="piece.player.color">
    <span [style.color]="piece.player.textColor">{{piece.player.displayName | shorternName}}</span>
  </div>


</div>

<div class="column side" style="color: white; justify-content: space-evenly;"
     [style.height.px]="((boxHeight + 10) * 9)">


  <a class="advert" href="https://cashsustainability.com/" target="_blank"><img src="assets/advert-2.jpg"></a>
  <a class="advert"
     href="https://estore.reconnaissance.net/product/a-guide-to-repurposing-unfit-shredded-paper-banknotes/"
     target="_blank"><img src="assets/advert-3.jpg"></a>
  <a class="advert" href="https://cashpaymentnews.com/" target="_blank"><img src="assets/advert-1.gif"></a>

</div>
<div *ngIf="firebase.debug" class="cheat-bar" style="position: absolute; right: 0; bottom: 0">
  <button (click)="roll(2)">2</button>
  <button (click)="roll(3)">3</button>
  <button (click)="roll(4)">4</button>
  <button (click)="roll(5)">5</button>
  <button (click)="roll(6)">6</button>
  <button (click)="roll(7)">7</button>
  <button (click)="roll(8)">8</button>
  <button (click)="roll(9)">9</button>
  <button (click)="roll(10)">10</button>
  <button (click)="roll(11)">11</button>
  <button (click)="roll(12)">12</button>
</div>
