<div [class]="'modal-outer ' + (closing ? 'modalOuterClosing' : '')">
  <div class="modal">
    <button (click)="dismiss()" class="home-button"><i class="fa-duotone fa-house"></i> Home</button>
    <img class="side-logo" src="assets/logo2-cropped.png">
    <div class="modal-title" style="color: black;">
      {{isSettings ? 'Info' : game?.status?.gameSettings?.maxPlayers == 1 ? 'Single Player' : 'Multiplayer'}}
    </div>

    <div *ngIf="!isSettings && game?.status?.gameSettings?.maxPlayers != 1" style="display: flex;
    flex-direction: column; align-items:center; justify-content: flex-start; margin: 10px;">

      <button class="copy-button" ngxClipboard [cbContent]="game?.id || ''" style=" ">
        <i class="fas fa-copy"></i>
        <div style="margin-left: 10px;">{{game?.id}}</div>
      </button>


      <span style="font-size: small; font-style: italic; text-align: center;">Copy the Game Id for another player to
        join or they can select
        the game from the Join Game section of the
        lobby by clicking the up arrow</span>

    </div>


    <div class="modal-content">
      <h3 style="margin: 7px;">Players </h3>
      <div *ngIf="!isSettings" style="font-size: small; font-style: italic;">You can change your name here</div>
      <div class="list">
        <div class="player-row" *ngFor="let activePlayer of game?.players">

          <div class="player" [style.box-shadow]="'1px 1px 1px 1px ' + activePlayer.shadowColor"
               [style.backgroundColor]="activePlayer.color">
            <span [style.color]="activePlayer.textColor">{{activePlayer.displayName | shorternName}}</span>
          </div>


          <input class="label-input" *ngIf="activePlayer.id == firebase.userId && !isSettings"
                 [(ngModel)]="nextPlayerName" (ngModelChange)="playerNameChanged()" placeholder="username" />
          <button *ngIf="!isSettings && activePlayer.id == firebase.userId" class="saveName" (click)="saveName()"
                  [disabled]="!(nameChanged && activePlayer.id == firebase.userId && !isSettings)">
            Save
          </button>

          <span style=" width: 280px; margin-left: 15px;"
                *ngIf="activePlayer.id != firebase.userId || isSettings">{{activePlayer.displayName}}</span>
          <i [style.opacity]="(game && game.actions && game.actions[activePlayer.id] && game.actions[activePlayer.id].type == 'roll') ? '1' : '0'"
             class="fas fa-dice"></i>

        </div>

      </div>


      <div class="divider"></div>
      <h3>Rules</h3>
      <div>

        <div style="  font-family: Arial, Helvetica, sans-serif !important;
        ">
          Pick up a card if you land on a green square. If you then move and land on another green square, or on a snake
          or ladder, pick up another and/move up or down the snake or ladder (oil slicks and flowers).

        </div>

        <div style="  font-family: Arial, Helvetica, sans-serif !important;
        ">Green cards have a topic, e.g. 'Energy' or 'Central Bank', and a statement about that topic. The resulting
          instruction to move reflects how much the statement positively enhances the environment.</div>

        <div style="  font-family: Arial, Helvetica, sans-serif !important;
      ">More than one piece may occupy a square.</div>

        <div style="  font-family: Arial, Helvetica, sans-serif !important;
        ">At the end, there is no need to roll the exact number of moves to finish!</div>

      </div>
    </div>



    <button class="close-button"
            [disabled]="!(isSettings || game?.status?.status == 'waiting' && game?.host == playerId) "
            (click)="close()">
      {{isSettings ? 'Continue...' : (game?.status?.status == 'waiting' && game?.host == playerId ? 'Start Game' :
      'Waiting for host')}}
    </button>

    <div *ngIf="!isSettings && game?.status?.gameSettings?.maxPlayers != 1" style="display: flex;
    flex-direction: column; align-items:center; justify-content: flex-start; margin: 10px; width: 250px;">
      <span style="font-size: small; font-style: italic; text-align: center;">Start the game when all the players you
        wish to play with have joined!</span>

    </div>

    <div *ngIf="!isSettings && game?.status?.gameSettings?.maxPlayers == 1" style="display: flex;
    flex-direction: column; align-items:center; justify-content: flex-start; margin: 10px; width: 250px;">
      <span style="font-size: small; font-style: italic; text-align: center;">Start the game when you are ready</span>

    </div>
  </div>



</div>
