<div>This game was invented by Reconnaissance International as part of its work to help the cash industry reduce its end
  to end environmental impact. </div>

<div>The aim is to introduce players to language and terms associated with sustainability and to the impact of a range
  of environmental actions that organisations choose to take so that you can consider what your organisation is doing.
</div>

<div>The board consists of 14 (grey) squares with key environmental messages that result in you either leaping forward
  or falling back in the game. There are also 27 (green) squares where you pick up a card. The card pack covers 18
  environmental topics from
  CO2 to Water. It offers a range of cards for each topic with three options, one which optimises the environmental
  impact, one that is ‘neutral’ and one that has little benefit. What you pick up determines if you move or not.</div>

<div>The game can be adapted for use with different audiences (consumers, staff, experts etc.), different sectors of the
  industry, or even different industries. It can be improved and changed. Please contact us at info@recon-intl.com. We
  would welcome your
  thoughts.
</div>

<img class="side-logo" src="assets/logo2-cropped.png">
