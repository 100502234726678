import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GameStatus } from 'shared';

@Component({
  selector: 'app-join-game',
  templateUrl: './join-game.component.html',
  styleUrls: ['./join-game.component.scss']
})
export class JoinGameComponent implements OnInit {
  @Input() games: GameStatus[] = [];
  gameId: string = '';
  constructor(public modal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  join(){
    this.modal.close({type: 'join', id: this.gameId})
  }

  create(){
    this.modal.close({type: 'create'})
  }

  dismiss(){
    this.modal.dismiss();
  }

}
