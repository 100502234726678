export function foo() {
  return 'barbie 2';
}

export class Success {
  success: boolean;
  message: string;
  status: number;
  data: any;

  constructor(data: any, message = 'Success') {
    this.success = true;
    this.message = message;
    this.data = data;
    this.status = 200;
  }
}

export class Error {
  success: boolean;
  message: string;
  status: number;

  constructor(message = 'Error', status = 500) {
    this.success = false;
    this.message = message;
    this.status = status;
  }
}

export class Action {
  type: string = ''; //card, snake, ladder, dice, won, started, created, action
  playerId: string = '';
  playerName: string = '';
  tick: number = 0;
  prompt: string = '';

  constructor(
    type: string,
    playerId: string,
    playerName: string,
    tick: number,
    diceNumber: number = 0
  ) {
    this.type = type;
    this.playerId = playerId;
    this.playerName = playerName;
    this.tick = tick;

    switch (type) {
      case 'card':
        this.prompt = ` drew a card`;
        break;
      case 'cash':
        this.prompt = ` drew a card`;
        break;
      case 'snake':
        this.prompt = ` fell down an oil pipe`;
        break;
      case 'ladder':
        this.prompt = ` climbed up a vine`;
        break;
      case 'dice':
        this.prompt = ` rolled a ` + diceNumber;
        break;
      case 'roll':
        this.prompt = ` rolled a ` + diceNumber;
        break;
      case 'won':
        this.prompt = ` won the game`;
        break;
      case 'started':
        this.prompt = ` started the game`;
        break;
      case 'created':
        this.prompt = ` created the game`;
        break;
      case 'action':
        this.prompt = ` did an action`;
        break;
      case 'join':
        this.prompt = ` joined the game`;
        break;
      default:
        this.prompt = ` did something`;
    }
  }
}
export class GameHistory {
  actions: Action[] = [];
  constructor() {}
}

export class Game {
  id: string;
  players: Player[];
  positions: { [id: string]: number };
  host: string = '';
  table: Cell[][];
  lastDice: any = { dice: 1, player: '', date: -1 };
  gameHistory: GameHistory = new GameHistory();
  actions: {
    [id: string]: {
      id: string;
      type: string;
      display: string;
      player: string;
      movingBackwards: boolean;
      hidePrompt: boolean;
      isSnakeOrLadder: boolean;
      snakePosition: number;
      ladderPosition: number;
      moved: boolean;
      moves: number;
    };
  } = {};
  isWon: boolean = false;
  winner: string = '';
  winnerId: string = '';
  cards: Card[] = [];
  burntCards: Card[] = [];
  cardIndex: number = -1;
  status: GameStatus = new GameStatus();
  constructor(id: string, players: Player[]) {
    this.id = id;
    this.players = players;
    this.positions = {};
    this.table = [];
    this.actions = {};
  }
}

export class GameSettings {
  privateGame: boolean = false;
  maxPlayers: number = 4;
  password: string = '';
  name: string = '';
  constructor() {}
}

export class GameStatus {
  status: string = 'waiting'; //'waiting', 'playing', 'finished'
  created: number = Date.now();
  lastMove: number = Date.now();
  currentTick: number = -1;
  markedForDeletion: boolean = false;
  id: string = '';
  players: number = 0;
  gameSettings: GameSettings = new GameSettings();
  constructor() {}
}

export class Card {
  type: string = '';
  statement: string = '';
  id: number;
  asset: string = '';
  reference: string = '';
  goal: string = '';
  moves: number = 0;
  category: string = '';
  classes: string = '';
  constructor(
    id: number,
    type: string,
    statement: string,
    reference: string,
    moves: number = 0
  ) {
    this.id = id;
    this.type = type;
    this.statement = statement;
    this.reference = reference;
  }
}

export class Cell {
  x: number;
  y: number;
  id: number;
  classes?: string = '';
  color: string = '';
  text?: string;
  players: string[];
  ladder?: number;
  snake?: number;
  constructor(x: number, y: number, id: number) {
    this.x = x;
    this.y = y;
    this.id = id;
    this.players = [];
  }
}

export class Player {
  id: string;
  displayName: string;
  color: string;
  shadowColor: string;
  textColor: string;

  constructor(id: string, displayName: string, color: string) {
    this.id = id;
    this.displayName = displayName;
    this.color = color;
    this.shadowColor = color;
    this.textColor = color;
  }
}

export class Coordinate {
  x: number;
  y: number;
  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }
}

export class Turn {
  player: Player;
  roll: number;
  moves: Move[];
  constructor(player: Player, roll: number, moves: Move[]) {
    this.player = player;
    this.roll = roll;
    this.moves = moves;
  }
}

export class Move {
  playerID: string;
  from: Coordinate;
  to: Coordinate;
  constructor(playerID: string, from: Coordinate, to: Coordinate) {
    this.playerID = playerID;
    this.from = from;
    this.to = to;
  }
}

export class GameStatistics {
  players: { [key: string]: PlayerStatistics } = {};
  dice: DiceStatistics[] = [
    new DiceStatistics(1),
    new DiceStatistics(2),
    new DiceStatistics(3),
    new DiceStatistics(4),
    new DiceStatistics(5),
    new DiceStatistics(6),
    new DiceStatistics(7),
    new DiceStatistics(8),
    new DiceStatistics(9),
    new DiceStatistics(10),
    new DiceStatistics(11),
    new DiceStatistics(12),
  ];
  cards: any[] = [];
  constructor() {}
}

export class PlayerStatistics {
  player: Player;
  turns: number = 0;
  positiveMoves: number = 0;
  negativeMoves: number = 0;
  snakes: number = 0;
  ladders: number = 0;
  cash: number = 0;

  snakeMoves: number = 0;
  ladderMoves: number = 0;
  constructor(player: Player) {
    this.player = player;
  }
}

export class DiceStatistics {
  dice: number;
  rolls: number = 0;
  constructor(dice: number) {
    this.dice = dice;
    this.rolls = 0;
  }
}

export function numberToSL(number: number) {
  let counter = 0;
  for (var i = 8; i >= 0; i--) {
    for (var j = 0; j <= 8; j++) {
      if (counter == number) {
        return [i, i % 2 == 0 ? j : 8 - j];
      }
      counter++;
    }
  }
  return [-1, -1];
}

export function randomHex() {
  return '#' + Math.floor(Math.random() * 16777215).toString(16);
}
