<!--
<div class="logo-box">
  <img src="assets/PNG/logo.png" alt="logo">
</div>

<div class="content-box">
  <div class="content-left">
    <h1>Create Game</h1>

    <div style="width: 100%; flex-grow: 2; display: flex; flex-direction: column;
    align-items: center; justify-content: flex-start;">
      <div class="label-input">
        <label>Username</label>
        <input [(ngModel)]="displayName" placeholder="username">
      </div>

      <div class="label-input">
        <label>Game Name</label>
        <input [(ngModel)]="gameSettings.name" placeholder="Unique Game Name" maxlength="20">
      </div>
      <div class="label-input">
        <label>Private / Public </label>
        <div [class]="'toggle ' + (gameSettings.privateGame ? 'on' : '')"
             (click)="gameSettings.privateGame = !gameSettings.privateGame; togglePrivate()">
          <div class="toggle-container">
            <div class="toggle-left"></div>
            <div class="toggle-center"><i [class]="'fas fa-' + (gameSettings.privateGame ? '' : 'un') + 'lock'"></i>
            </div>
            <div class="toggle-right"></div>

          </div>
          <div class="toggle-value">{{gameSettings.privateGame ? 'Private' : 'Public'}}</div>

        </div>
      </div>


      <div class="label-input">
        <label>Password</label>
        <input oninput="this.value = this.value.toUpperCase()" [(ngModel)]="gameSettings.password"
               [disabled]="!gameSettings.privateGame">
      </div>

      <div class="label-input">
        <label>Maximum Players</label>
        <div class="slider-container">

          <input class="slider" type="range" min="1" max="6" [(ngModel)]="gameSettings.maxPlayers"
                 (input)="updateSliderValue(true)">
          <div class="slider-value" [style.left.px]="sliderPosition"><span>{{
              gameSettings.maxPlayers
              }}</span></div>
        </div>
      </div>
    </div>




    <button class="play-button" (click)="create()">Play</button>



  </div>

  <div class="router-bar">
    <a *ngFor="let route of routes" [routerLink]="route.path" routerLinkActive="true"
       [class]="'router-bar-link ' + (currentRoute == route.path ? 'active' : '')">{{route.name}}</a>

  </div>
  <div class="content-center">

    <router-outlet></router-outlet>

  </div>
  <div class="content-right">
    <h1>Join Game</h1>

    <div style="width: 100%; display: flex; flex-direction: column;
    align-items: center; justify-content: flex-start;">
      <div class="label-input">
        <label>Username</label>
        <input [(ngModel)]="displayName" placeholder="username">
      </div>



      <div class="label-input">
        <label>Game Id</label>

        <div class="active-game" style="height: 2.5rem; border-radius: 1.25rem; ">
          <div class="active-game-info">
            <input class="active-game-id"
                   style="height: 100%; outline: none !important; font-weight: bold; font-size: larger; margin: 0;border-radius: 0; "
                   oninput="this.value = this.value.toUpperCase()" placeholder="GAMEID" [(ngModel)]="serverId"
                   placeholder="">
          </div>

          <button (click)="join()" class="active-game-action play" style="height: 2.5rem; font-size: medium;"><i
               [class]="'fas fa-play'" style="font-size: medium;"></i></button>
        </div>

      </div>

    </div>



  </div>


</div>


-->

<!--
  <div class="label-input">
            <label>Username</label>
            <input [(ngModel)]="displayName" placeholder="username" />
          </div>

           <div class="label-input">
            <label>Private / Public </label>
            <div [class]="'toggle ' + (gameSettings.privateGame ? 'on' : '')" (click)="
                gameSettings.privateGame = !gameSettings.privateGame;
                togglePrivate()
              ">
              <div class="toggle-container">
                <div class="toggle-left"></div>
                <div class="toggle-center">
                  <i [class]="
                      'fas fa-' +
                      (gameSettings.privateGame ? '' : 'un') +
                      'lock'
                    "></i>
                </div>
                <div class="toggle-right"></div>
              </div>
              <div class="toggle-value">
                {{ gameSettings.privateGame ? "Private" : "Public" }}
              </div>
            </div>
          </div>

          <div class="label-input">
            <label>Password</label>
            <input oninput="this.value = this.value.toUpperCase()" [(ngModel)]="gameSettings.password"
                   [disabled]="!gameSettings.privateGame" />
          </div>
-->
<img class="side-flower" src="assets/vines/flower-2.png">
<img class="background-tree" src="assets/landing/background-tree.png">

<div class="header-bar">
    <div class="header-box">
        <a *ngFor="let route of routes" [routerLink]="route.path" routerLinkActive="true" [class]="
        'router-bar-link ' + (currentRoute == route.path ? 'active' : '')
      ">{{ route.name }}</a>
    </div>
</div>

<div class="lobby-center">
    <div class="sun" style="z-index: -1"></div>



    <div class="lobby-left">
        <div class="lobby-left-left">
            <div class="lobby-logo">
                <img src="assets/logo.svg" />
            </div>
            <div style="flex-grow: 1;"></div>
            <div class="lobby-actions">
                <div class="lobby-create">
                    <div class="button-row">
                        <button class="icon-button tooltip"><span class="tooltiptext">Click 'Play Game' to jump straight into a new game</span><i
   class="fas fa-info"></i></button>
                        <h1>Single Player</h1>
                        <button class="icon-button" style="opacity: 0; pointer-events: none;">
<i ></i>
</button>
                    </div>

                    <div style="
        width: 100%;
        flex-grow: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
      ">


                        <!--
                  <div class="label-input">
                    <label>Game Name</label>
                    <input [(ngModel)]="gameSettings.name" placeholder="Optional Game Name" maxlength="20" />
                </div>


                <div class="label-input">
                    <label>Maximum Players</label>
                    <div class="slider-container">
                        <input class="slider" type="range" min="1" max="6" [(ngModel)]="gameSettings.maxPlayers" (input)="updateSliderValue(true)" />
                        <div class="slider-value" [style.left.px]="sliderPosition">
                            <span>{{ gameSettings.maxPlayers }}</span>
                        </div>
                    </div>
                </div>
                -->
                    </div>

                    <button class="play-button" (click)="create()">Play Game</button>
                </div>

                <div class="lobby-create">
                    <div class="button-row">
                        <button class="icon-button tooltip"><span class="tooltiptext">Click 'Play With Others' to join or create a game!</span><i
 class="fas fa-info"></i></button>
                        <h1>Multi Player</h1>
                        <button class="icon-button" style="opacity: 0; pointer-events: none;">
<i ></i>
</button>
                    </div>

                    <div style="
      width: 100%;
      flex-grow: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    ">


                        <!--
                <div class="label-input">
                  <label>Game Name</label>
                  <input [(ngModel)]="gameSettings.name" placeholder="Optional Game Name" maxlength="20" />
              </div>


              <div class="label-input">
                  <label>Maximum Players</label>
                  <div class="slider-container">
                      <input class="slider" type="range" min="1" max="6" [(ngModel)]="gameSettings.maxPlayers" (input)="updateSliderValue(true)" />
                      <div class="slider-value" [style.left.px]="sliderPosition">
                          <span>{{ gameSettings.maxPlayers }}</span>
                      </div>
                  </div>
              </div>
              -->
                    </div>

                    <button class="play-button" (click)="openMultiplayer()">Play With Others</button>
                </div>


            </div>
        </div>

        <div class="lobby-actions-dice-join">
            <div class="lobby-dice">
                <img src="assets/PNG/Asset 32.png" />
            </div>


        </div>

    </div>



    <div class="lobby-right">
        <router-outlet></router-outlet>

    </div>
</div>

<a target="_blank" href="https://www.reconnaissance.net" style="
    position: absolute;
    z-index: 1000;
    bottom: -2px;
    margin: 0 auto;
    color: rgb(253, 241, 241);
    left: auto;
    right: auto;
    width: 500px;
    left: calc(50% - 175px);
    text-align: center;
    text-shadow: 1px 1px rgb(65, 63, 63);
  ">Reconnaissance International Limited – Cash ESG©
</a>

<!--
    <div class="lobby-join">
                    <div [class]="'lobby-absolute ' + (joinExpanded ? 'expanded' : '')">
                        <div class="button-row">
                            <button class="icon-button tooltip"><span class="tooltiptext">Click 'Play With Others' to join or create a game!</span><i
                   class="fas fa-info"></i></button>
                            <h1>Multiplayer</h1>
                            <button class="icon-button" style="opacity: 0; pointer-events: none;">
                              <i ></i>
                            </button>
                        </div>
                        <button class="play-button" (click)="join()">Play With Others</button>

 <div style="
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
              ">

                            <div class="label-input">
                                <label>Game Id</label>

                                <div class="active-game" style="height: 2.5rem; border-radius: 1.25rem">
                                    <div class="active-game-info">
                                        <input class="active-game-id" style="
                        height: 100%;
                        outline: none !important;
                        font-weight: bold;
                        font-size: larger;
                        margin: 0;
                        border-radius: 0;
                      " oninput="this.value = this.value.toUpperCase()" placeholder="GAMEID" [(ngModel)]="serverId" placeholder="e.g. I7AS34" />
                                    </div>

                                    <button (click)="join()" class="active-game-action play" style="height: 2.5rem; font-size: medium">
                    <i [class]="'fas fa-play'" style="font-size: medium"></i>
                  </button>
                                </div>
                            </div>

                            <span *ngIf="joinExpanded" style="
                  font-size: large;
                  font-weight: bold;
                  margin: 10px 0px 5px 0px;
                ">Active Games</span>
                            <div class="list" style="width: 100%" *ngIf="joinExpanded">
                                <div class="active-game" *ngFor="let game of games">
                                    <div class="active-game-info">
                                        <div class="active-game-players">
                                            Players: {{ game.players }} / {{ game.gameSettings.maxPlayers }}
                                        </div>
                                        <div class="active-game-id">
                                            {{ game.gameSettings.name }}
                                        </div>
                                        <div class="active-game-status">
                                            {{ game.status | capitalize }}
                                        </div>
                                        <div class="active-game-private">
                                            <i [class]="
                          'fas fa-' +
                          (game.gameSettings.privateGame ? 'lock' : 'unlock')
                        "></i>
                                        </div>
                                    </div>
                                    <button (click)="serverId = game.id; join()" [class]="
                      'active-game-action ' +
                      (game.gameSettings.maxPlayers == game.players ||
                      game.status != 'waiting'
                        ? 'wait'
                        : 'play')
                    ">
                    <i [class]="
                        'fas fa-' +
                        (game.gameSettings.maxPlayers == game.players ||
                        game.status != 'waiting'
                          ? 'eye'
                          : 'play')
                      "></i>
                  </button>
                                </div>
                            </div>
                        </div>



                      </div>
                    </div>
-->
