<app-cash style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;"></app-cash>

<div class="modal-outer" *ngIf="showModal">
  <div class="modal" style="width: 700px;">
    <div class="modal-title">
      {{game?.winnerId === fb.userId ? 'Congratulations!' : 'Game Over!'}}
    </div>

    <div class="modal-winner">
      <div class="modal-winner-title">
        {{game?.winner}}
      </div>
      <div class="modal-winner-name">
        is the winner
      </div>
    </div>

    <span style="margin: 20px; color: white; font-style: italic;">Click the card to flick through your cards from this
      game</span>
    <div class="cards-container" (click)="nextCard($event)" id="cards-container">
      <div class="deck" id="victory-deck">
        <div *ngFor="let card of cards " [class]="'card card-animation ' + position">
          <div class="card-inner">
            <img class="card-back full-card-back" src="./assets/card-back.png">
            <div class="card-front">
              <img [src]="'assets/cards/' + card.asset + '.png'">
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="dice-statistics" *ngIf="false">

      <div class="dice-rolls">
        <div *ngFor="let dice of gameStatistics.dice" [style.height.px]="dice.rolls * pxPerCell" class="dice-roll">
          <div>{{dice.rolls}}</div>
        </div>
      </div>
      <div class="dice-numbers">
        <div *ngFor="let dice of gameStatistics.dice">
          {{dice.dice}}
        </div>
      </div>
    </div>

    <div class="player-statistics">
      <h3 style="text-align: center; font-size: medium;"> Weighing up the squares you landed on and the cards you picked
        up, overall your
        net environmental impact was:
      </h3>
      <div class="cards-row" style="width: 100% !important; min-width: 5px; overflow: hidden; flex-shrink: 0;">
        <div class="player" *ngFor="let player of gameStatistics.players | keyvalue">
          <div>{{player.value.player.displayName}}</div>
          <div>{{scores[player.value.player.id]}}%</div>
        </div>

      </div>

    </div>
    <button class="close-button" (click)="close()">Continue</button>
    <div class="button-row" style="width: 200px;">
      <!-- AddToAny BEGIN -->
      <!-- AddToAny BEGIN -->
      <!-- AddToAny BEGIN -->


      <a href="https://www.addtoany.com/add_to/linkedin?linkurl=https%3A%2F%2Fgame.cashsustainability.com&amp;linkname=I%20just%20played%20a%20sustainable%20game%2C%20Cash%20ESG!"
         target="_blank"><img src="https://static.addtoany.com/buttons/linkedin.svg" width="32" height="32"></a>
      <a href="https://www.addtoany.com/add_to/twitter?linkurl=https%3A%2F%2Fgame.cashsustainability.com&amp;linkname=I%20just%20played%20a%20sustainable%20game%2C%20Cash%20ESG!"
         target="_blank"><img src="https://static.addtoany.com/buttons/twitter.svg" width="32" height="32"></a>
      <a href="https://www.addtoany.com/add_to/facebook?linkurl=https%3A%2F%2Fgame.cashsustainability.com&amp;linkname=I%20just%20played%20a%20sustainable%20game%2C%20Cash%20ESG!"
         target="_blank"><img src="https://static.addtoany.com/buttons/facebook.svg" width="32" height="32"></a>
      <a href="https://www.addtoany.com/add_to/google_gmail?linkurl=https%3A%2F%2Fgame.cashsustainability.com&amp;linkname=I%20just%20played%20a%20sustainable%20game%2C%20Cash%20ESG!"
         target="_blank"><img src="https://static.addtoany.com/buttons/gmail.svg" width="32" height="32"></a>
      <a href="https://www.addtoany.com/add_to/email?linkurl=https%3A%2F%2Fgame.cashsustainability.com&amp;linkname=I%20just%20played%20a%20sustainable%20game%2C%20Cash%20ESG!"
         target="_blank"><img src="https://static.addtoany.com/buttons/email.svg" width="32" height="32"></a>


      <!-- AddToAny END -->

      <!-- AddToAny END -->
    </div>


  </div>
</div>
