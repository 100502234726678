import { Component } from '@angular/core';
import { Game, foo } from 'shared';
import { FirebaseService } from './firebase.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'SnakesAndLadders';

  constructor(public firebase: FirebaseService) {
    if (!localStorage.getItem('userId')) {
      var userId = Math.random().toString(36).substring(7);
      localStorage.setItem('userId', userId);
    }
    firebase.userId = localStorage.getItem('userId')!;
  }
}
