// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  useEmulators: false,
  firebase: {
    projectId: 'snakesandladders-352b8',
    appId: '1:754806919600:web:273fa686eb9830a4618675',
    databaseURL: 'https://snakesandladders-352b8-default-rtdb.europe-west1.firebasedatabase.app',
    storageBucket: 'snakesandladders-352b8.appspot.com',
    apiKey: 'AIzaSyBdhBneKKP4CjmiWr5dCWJWc8NT-QsNrlo',
    authDomain: 'snakesandladders-352b8.firebaseapp.com',
    messagingSenderId: '754806919600',
    measurementId: 'G-YKLHXLBF3E',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
