import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Game } from 'projects/shared';
import { FirebaseService } from '../../firebase.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  @Input('isSettings') isSettings: boolean = true; //is either start of game or settings
  @Input('game') game?: Game;
  @Input('playerId') playerId: string = '';

  playerName: string = '';
  nextPlayerName: string = '';
  nameChanged: boolean = false;
  closing: boolean = false;
  constructor(
    private activeModal: NgbActiveModal,
    public firebase: FirebaseService
  ) {}

  ngOnInit(): void {
    if (!this.isSettings) {
      console.log(this.game?.players.find((a) => a.id == this.playerId));
      this.playerName =
        this.game?.players.find((a) => a.id == this.playerId)?.displayName ||
        '';
      this.nextPlayerName = this.playerName + '';
    }
  }

  async close() {
    this.closing = true;
    await this.await(1);
    if (this.isSettings) {
      this.activeModal.close();
      return;
    } else if (this.game?.status.gameSettings.maxPlayers == 1) {
      this.activeModal.close();
      return;
    }

    var confirm = window.confirm('Are all the players ready?');
    if (confirm) {
      this.activeModal.close();
    }
  }

  playerNameChanged() {
    this.nameChanged = this.nextPlayerName.trim() != this.playerName;
  }

  saveName() {
    console.log(this.nextPlayerName);
    if (!this.game) {
      return;
    }
    localStorage.setItem('displayName', this.nextPlayerName);
    this.firebase.updatePlayerName(
      this.game?.id,
      this.playerId,
      this.nextPlayerName
    );
  }

  async dismiss() {
    this.closing = true;
    await this.await(1);
    this.activeModal.dismiss();
  }

  async await(s: number) {
    return new Promise((resolve) => setTimeout(resolve, s * 1000));
  }
}
