import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cash',
  templateUrl: './cash.component.html',
  styleUrls: ['./cash.component.scss'],
})
export class CashComponent implements OnInit {
  constructor() {}

  cashItems: {
    value: number;
    left: string;
    transform: string;
    height: string;
    isLeaf: boolean;
    isClockwise?: boolean;
  }[] = [];

  ngOnInit(): void {
    var startTime = new Date().getTime();
    // Generate and animate falling cash
    var int = setInterval(() => {
      // Stop after 5 seconds
      if (new Date().getTime() - startTime > 5000) {
        clearInterval(int); // Stop the interval

        setTimeout(() => {
          this.cashItems = [];
        }, 5000);

        return;
      }
      const value = Math.floor(Math.random() * 100) + 1; // Random cash value
      const left = Math.random() * 100 + 'vw'; // Random horizontal position
      // random rotation angle between -45 and 45
      const transform = 'rotate(' + (Math.random() * 90 - 45) + 'deg)';
      var isLeaf = Math.random() < 0.3;
      // random height between 20 and 60px
      const height = Math.random() * (isLeaf ? 30 : 50) + 20 + 'px';

      this.cashItems.push({
        value,
        left,
        transform,
        height,
        isLeaf,
        isClockwise: Math.random() < 0.5,
      });

      // Remove cash element after animation is complete
      setTimeout(() => {
        this.cashItems.shift();
      }, 5000); // Adjust the time based on your animation duration
    }, 25); // Adjust the interval to control the rate of falling cash
  }
}
