<div class="contact-container">

  <p>If you have any questions or inquiries, feel free to get in touch with us.</p>

  <address>
    <p><strong>Address:</strong> 2.4 The Beacon, Beaufront Park, Anick Road, Hexham, Northumberland, NE46 4TU, UK</p>
    <p><strong>Email:</strong>&nbsp; <a href="mailto:info@recon-intl.com">info@recon-intl.com</a></p>
    <p><strong>Phone:</strong> +44 (0)1932 785 680</p>
  </address>

  <h2>Contact Form</h2>
  <form action="/submit" method="post">
    <div class="form-group">
      <label for="name">Name:</label>
      <input [(ngModel)]="name" type="text" id="name" name="name" required placeholder="John Smith">
    </div>
    <div class="form-group">
      <label for="email">Email:</label>
      <input [(ngModel)]="email" type="email" id="email" name="email" required placeholder="john@smith.com">
    </div>
    <div class="form-group">
      <label for="message">Message:</label>
      <textarea [(ngModel)]="message" id="message" name="message" rows="4" required
                placeholder="The game is great! Thank you so much"></textarea>
    </div>
    <div class="form-group">
      <button type="submit" (click)="sendEmail(name, email, message)">Submit</button>
    </div>
  </form>
</div>
