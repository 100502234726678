import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../../firebase.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  name: string = '';
  email: string = '';
  message: string = '';

  constructor(private fb: FirebaseService) {}

  ngOnInit(): void {}

  sendEmail(name: string, email: string, message: string) {
    if (!name) {
      alert('Please enter your name');
      return;
    }

    if (!email || !email.includes('@')) {
      alert('Please enter your email');
      return;
    }

    if (!message) {
      alert('Please enter your message');
      return;
    }

    console.log('emailing: ' + email);
    this.fb
      .sendEmail(name, email, message)
      .then((a) => {
        alert('Email Sent');
      })
      .catch((e) => {
        alert('Error sending email: ' + e);
      });
  }
}
