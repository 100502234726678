import { Component, OnInit, Renderer2 } from '@angular/core';
import { FirebaseService } from '../../firebase.service';
import { Card } from 'shared';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss'],
})
export class CardsComponent implements OnInit {
  cards: Card[] = [];
  categories: { [cat: string]: Card[] } = ({} = {});
  cardIndex: number = -1;
  currentCategory: string = 'CO2';
  position = 'center';
  position2 = 'center';
  working: boolean = false;
  shiftSize: number = 300;

  constructor(private fb: FirebaseService, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.getCards();
  }

  async getCards() {
    this.cards = await this.fb.getCards();
    var temp: { [cat: string]: Card[] } = {};
    this.cards.forEach((c) => {
      if (temp[c.category]) {
        temp[c.category].push(c);
      } else {
        temp[c.category] = [c];
      }
    });
    this.categories = temp;

    console.log(this.categories);

    setTimeout(() => {
      this.initCards();
      setTimeout(() => {
        this.littleScroll();
        this.nextCard({ clientX: 0 });
      },200)
    }, 50);
  }

  async littleScroll(){
    var bar = document.getElementById('row-with-scroll');
    if(!bar) {
      console.log('no bar');
      return;
    }

    //bar is a div with elements and a scrollbar horizontal, slide it a bit
    for(var i = 0; i < 150; i+=1) {

      bar.scrollLeft = Math.floor(i);
      await this.waitN(4);
    }
    for(var i = 150; i >= 0; i-=1) {

      bar.scrollLeft = Math.floor(i);
      await this.waitN(4);
    }
  }

  async initCards() {
    var cardsContainer = document.getElementById('lobby-deck');

    if (!cardsContainer) return;

    //so cards is a deck of cards, with z-index so that top card is index 0 and bottom card is index cards.length - 1, cardIndex is the current front card top of deck,
    //any cards before cardindex should be on left, any after should be on right by shifting their absolute left position by 260px
    var sizeOfRightCards = this.cardIndex;
    var stepSize = 0.2;
    for (var i = 0; i <= this.cardIndex; i++) {
      var card = cardsContainer.children[i] as HTMLDivElement;
      card.style.left = this.shiftSize - 250 + 'px';

      var step = this.cardIndex - i;
      var z = 501 - step;
      var rotateZ = 0 - step * stepSize;
      var rotateY = 0;
      var rotateX = 0;

      card.style.zIndex = `${z}`;
      this.renderer.setStyle(
        card,
        'transform',
        `rotateX(${rotateX}deg) rotateY(${rotateY}deg) rotateZ(${rotateZ}deg)`
      );
    }

    var sizeOfLeftCards = cardsContainer.children.length - this.cardIndex - 1;
    console.log(sizeOfLeftCards);
    var stepSize = 0.2;
    for (var i = this.cardIndex + 1; i < cardsContainer.children.length; i++) {
      var card = cardsContainer.children[i] as HTMLDivElement;
      card.style.left = -this.shiftSize + 'px';

      var step = (this.cardIndex + 1 - i) * -1;
      var z = 500 - step - (this.cardIndex + 1);
      var rotateZ = 0 - step * stepSize;
      var rotateY = 0;
      var rotateX = 0;

      card.style.zIndex = `${z}`;
      this.renderer.setStyle(
        card,
        'transform',
        `rotateX(${rotateX}deg) rotateY(${rotateY}deg) rotateZ(${rotateZ}deg)`
      );
      console.log(
        ' step: (z,x,y,z)=' +
          step +
          ': (' +
          z +
          ',' +
          rotateX +
          ',' +
          rotateY +
          ',' +
          rotateZ +
          ')'
      );
    }
  }

  nextCategory(cat: string) {
    this.position2 = 'before';
    this.position = 'center';
    this.cardIndex = -1;
    this.currentCategory = cat;
    setTimeout(() => {
      this.initCards();
    }, 20);
  }

  async nextCard(event: any) {
    if (this.working) return;
    var deck = document.getElementById('lobby-deck');
    if (!deck) {
      return;
    }
    //check if clicked on left or right of deck view
    var deckCoords = deck.getBoundingClientRect();

    var left = event.clientX < deckCoords.left + deckCoords.width / 2;

    console.log(left);
    if (!left) {
      //flip card backwards
      if (this.cardIndex < 0) {
        this.cardIndex = -1;
        this.initCards();
        return;
      }
      this.working = true;
      await this.flipCardBackwards(deck);
      this.working = false;
      this.initCards();
      return;
    } else {
    }

    if (this.cardIndex >= deck.children.length - 1) {
      this.cardIndex = deck.children.length - 1;
      this.initCards();
      return;
    }

    this.working = true;
    await this.flipCardForwards(deck);
    this.working = false;

    this.initCards();
  }

  async flipCardForwards(deck?: HTMLElement) {
    this.cardIndex++;
    //get card at that index

    if (!deck) return;
    var card = deck.children[this.cardIndex] as HTMLDivElement;
    if (!card) return;
    console.log(card, this.cardIndex);

    //flip the card to the left
    card.style.left = this.shiftSize - 250 + 'px';
    card.style.zIndex = '600';
    (card.children[0] as HTMLDivElement).style.transform = 'rotateY(180deg)';
    await this.waitN(400);
  }

  async flipCardBackwards(deck?: HTMLElement) {
    this.cardIndex--;
    //get card at that index

    if (!deck) return;
    var card = deck.children[this.cardIndex + 1] as HTMLDivElement;
    if (!card) return;

    //flip the card to the left
    card.style.left = -this.shiftSize + 'px';
    card.style.zIndex = '600';
    (card.children[0] as HTMLDivElement).style.transform = 'rotateY(0deg)';
    await this.waitN(400);
  }

  async waitN(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
