import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-dice',
  templateUrl: './dice.component.html',
  styleUrls: ['./dice.component.scss'],
})
export class DiceComponent implements OnInit {
  nextRoll: number = 0;
  @Input('rollSpeed') rollSpeed: number = 5000;
  @Input('roll') roll?: Observable<any>;

  current: number = 0;

  diceOne?: HTMLDivElement;
  diceTwo?: HTMLDivElement;
  button?: HTMLDivElement;
  randomId: string = Math.random().toString(36).substring(7);
  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    if (!this.roll) {
      return;
    }
    this.diceOne = document.getElementById(
      'cube-' + this.randomId
    ) as HTMLDivElement;
    this.roll.subscribe((roll) => {
      this.nextRoll = Number(roll);
      this.rollDice();
    });
  }

  async rollDice() {
    var diceOne = this.nextRoll;
    if (this.current == this.nextRoll) {
      //do a jump positio
      for (var i = 1; i <= 6; i++) {
        this.diceOne?.classList.remove('show-' + i);
        if ((diceOne - 1) % 6 === i) {
          this.diceOne?.classList.add('show-' + i);
        }
      }

      await this.sleep(500);
    }
    await this.sleep(500);
    for (var i = 1; i <= 6; i++) {
      this.diceOne?.classList.remove('show-' + i);

      if (diceOne === i) {
        this.diceOne?.classList.add('show-' + i);
      }
    }
    this.current = diceOne;
  }

  //simple timeout
  async sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
