import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';

import { provideDatabase, getDatabase } from '@angular/fire/database';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';

import { FirebaseService } from './firebase.service';
import { GameComponent } from './Pages/game/game.component';
import { LobbyComponent } from './Pages/lobby/lobby.component';
import { FormsModule } from '@angular/forms';
import { DiceComponent } from './dice/dice.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ActionComponent } from './Modals/action/action.component';
import { PlayerMovesPipePipe } from './Pipes/player-moves-pipe.pipe';
import { CapitalizePipe } from './Pipes/capitalize.pipe';
import { AboutComponent } from './Pages/about/about.component';
import { HowToComponent } from './Pages/how-to/how-to.component';
import { CardsComponent } from './Pages/cards/cards.component';
import { ContactComponent } from './Pages/contact/contact.component';
import { SponsorsComponent } from './Pages/sponsors/sponsors.component';
import { CustomSlicePipe } from './Pipes/custom-slice.pipe';
import { VictoryComponent } from './Modals/victory/victory.component';
import { CashComponent } from './Models/cash/cash.component';
import { AbsPipe } from './Pipes/abs.pipe';
import { PasswordComponent } from './Models/password/password.component';
import { ShorternNamePipe } from './Pipes/shortern-name.pipe';
import { SettingsComponent } from './Modals/settings/settings.component';
import { ToLengthPipe } from './Pipes/to-length.pipe';
import { JoinGameComponent } from './Modals/join-game/join-game.component';
import { ClipboardModule } from 'ngx-clipboard';
@NgModule({
  declarations: [
    AppComponent,
    GameComponent,
    LobbyComponent,
    DiceComponent,
    ActionComponent,
    PlayerMovesPipePipe,
    CapitalizePipe,
    AboutComponent,
    HowToComponent,
    CardsComponent,
    ContactComponent,
    SponsorsComponent,
    CustomSlicePipe,
    VictoryComponent,
    CashComponent,
    AbsPipe,
    PasswordComponent,
    ShorternNamePipe,
    SettingsComponent,
    ToLengthPipe,
    JoinGameComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideDatabase(() => getDatabase()),
    provideFunctions(() => getFunctions()),
    NgbModule,
    ClipboardModule

  ],
  providers: [FirebaseService],
  bootstrap: [AppComponent],
})
export class AppModule {}
