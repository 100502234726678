<div class="modal-outer">
  <div class="modal">
    <img class="side-logo" style="right: 20px !important; left: auto !important;" src="assets/logo2-cropped.png">
    <button class="home-button" (click)="dismiss()">X</button>
    <div class="modal-title" style="color: black;">
      Multi Player Game
    </div>
    <div class="multiplay-types">
      <div class="multiplayer-option">
        <h2>Join Game</h2>
        <div class="label-input">


          <div class="active-game" style="height: 2.5rem; border-radius: 1.25rem">
            <div class="active-game-info">
              <input class="active-game-id" oninput="this.value = this.value.toUpperCase()" placeholder="GAMEID"
                     [(ngModel)]="gameId" placeholder="Game ID e.g. I7AS34" />
            </div>

            <button (click)="join()" class="active-game-action play" style="height: 2.5rem; font-size: medium">
              <i [class]="'fas fa-play'" style="font-size: medium"></i>
            </button>
          </div>
        </div>

        <label>You can join a game by entering the 6 letter ID of a friends game, they can find this code on the popup
          after clicking Create Game</label>
      </div>

      <div class="multiplayer-option">
        <h2>Create Game</h2>
        <div class="label-input">


          <button class="play-button" (click)="create()">Create Game</button>
        </div>

        <label>Create a game and get a unique game Id to share with friends</label>
      </div>


      <div class="multiplayer-option">
        <h2>Find Game</h2>
        <label>Find available games to join or spectate</label>

        <div class="list" style="width: 100%">
          <div class="active-game" *ngFor="let game of games">
            <div class="active-game-info">
              <div class="active-game-players">
                Players: {{ game.players }} / {{ game.gameSettings.maxPlayers }}
              </div>
              <div class="active-game-id">
                {{ game.gameSettings.name }}
              </div>
              <div class="active-game-status">
                {{ game.status | capitalize }}
              </div>
              <div class="active-game-private">
                <i [class]="
            'fas fa-' +
            (game.gameSettings.privateGame ? 'lock' : 'unlock')
          "></i>
              </div>
            </div>
            <button (click)="gameId = game.id; join() " [class]="
        'active-game-action ' +
        (game.gameSettings.maxPlayers == game.players ||
        game.status != 'waiting'
          ? 'wait'
          : 'play')
      ">
              <i [class]="
          'fas fa-' +
          (game.gameSettings.maxPlayers == game.players ||
          game.status != 'waiting'
            ? 'eye'
            : 'play')
        "></i>
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>

</div>
