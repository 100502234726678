import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { FirebaseService } from '../../firebase.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Card, Cell, Game, Player, numberToSL } from 'shared';
import { Database, Unsubscribe, onValue, ref } from '@angular/fire/database';
import { LadderService } from '../../ladder.service';
import { timeout } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionComponent } from '../../Modals/action/action.component';
import { VictoryComponent } from '../../Modals/victory/victory.component';
import { SoundsService } from '../../Services/sounds.service';
import { SettingsComponent } from '../../Modals/settings/settings.component';

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss'],
})
export class GameComponent implements OnInit {
  id: string = '';
  database: Database = inject(Database);
  game?: Game = new Game('', []);
  prompt: string = '';
  players: { [id: string]: Player } = {};
  ladders: Cell[] = [];
  lastRoll: any = { id: 1, player: '', time: -1 };

  ctx?: CanvasRenderingContext2D;
  canvas!: HTMLCanvasElement;
  table?: HTMLDivElement;
  pieces: any[] = [];
  firstInit: boolean = true;
  currentTick = -1;
  settingsClosed: boolean = false;
  activeCards: Card[] = [];
  burntCards: Card[] = [];

  cardIndex = -1;

  activePlayer: Player | undefined;
  boxHeight: number = 100;

  gameSubscription: Unsubscribe | undefined;

  nextRoll = new EventEmitter();
  rollObserver = this.nextRoll.asObservable();
  nextRoll2 = new EventEmitter();
  rollObserver2 = this.nextRoll2.asObservable();
  constructor(
    public firebase: FirebaseService,
    private activeRouter: ActivatedRoute,
    private router: Router,
    public ladder: LadderService,
    public modal: NgbModal,
    public audio: SoundsService
  ) {
    var params: any = this.activeRouter.snapshot.queryParams;

    var pageWidth = window.innerWidth;
    var pageHeight = window.innerHeight;
    if (params.id) {
      this.id = params.id;
    } else {
      this.router.navigate(['']);
      return;
    }
  }

  TAG = 'GameComponent';

  log(message: string, ...optionalParams: any[]) {
    console.log(
      this.TAG + ': ' + message,
      optionalParams.reduce((acc, curr) => {
        return acc + '\n   ' + JSON.stringify(curr);
      }, '')
    );
  }

  async checkCardChanges() {
    if (Number(this.game?.cardIndex) >= 0) {
      this.cardIndex = this.game?.cardIndex!;
    }
    if (this.game?.cards) {
      this.activeCards = this.game.cards.slice(this.cardIndex + 1);
      this.burntCards = this.game.cards.slice(0, this.cardIndex + 1).reverse();
    }
    if (this.game?.cardIndex != this.cardIndex) {
      this.cardIndex = this.game?.cardIndex!;
    }
  }

  ngOnInit(): void {
    this.boxHeight = Math.floor((window.innerHeight - 90) / 9) - 4;
    this.audio.loadAudio();
  }

  ngAfterViewInit() {}

  ngAfterContentInit() {
    this.canvas = document.getElementById('gameCanvas') as HTMLCanvasElement;
    this.table = document.getElementById('gameTable') as HTMLDivElement;
    if (!this.canvas || !this.table) return;
    this.ctx = this.canvas.getContext('2d')!;
    this.gameSubscription = this.followGame(this.id);
  }

  ngOnDestroy() {
    this.modal.dismissAll();

    if (this.gameUpdateTimeout) {
      clearTimeout(this.gameUpdateTimeout);
    }

    try {
      if (this.gameSubscription) {
        this.gameSubscription();
      }
    } catch (e) {}
  }

  timeout: any;
  //view changed listener
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    console.log('resize');
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.boxHeight = Math.floor((window.innerHeight - 90) / 9) - 4;

      if (this.game) {
        this.firstInit = true;
        this.sortTable();
      }
    }, 100);
  }

  async openAction(cell: Cell, type: string = 'action'): Promise<string> {
    var dialogClass = '';

    if (type == 'cash') {
      dialogClass = 'modal-card-animation';
    } else {
      dialogClass = 'modal-dialog action-modal';
    }
    return new Promise((res, rej) => {
      const modalRef = this.modal.open(ActionComponent, {
        centered: true,
        size: 'lg',
        backdropClass: 'modal-backdrop',
        windowClass: 'modal-window ',
        modalDialogClass: dialogClass,
        backdrop: 'static',
      });

      if (type == 'cash') {
        this.audio.playCard();
        const card = this.game?.cards[this.game.cardIndex];
        this.log('opening card', card);
        modalRef.componentInstance.card = card;

        //get the top card of the active cards
        var startDeck = document.getElementById(
          'activeCards'
        ) as HTMLDivElement;
        var burtnDeck = document.getElementById('burntCards') as HTMLDivElement;

        var startDiv = startDeck as HTMLDivElement;
        var endDiv = burtnDeck as HTMLDivElement;

        modalRef.componentInstance.parentEndDiv = !endDiv;
        modalRef.componentInstance.startDiv = startDiv || startDeck;
        modalRef.componentInstance.endDiv = endDiv || burtnDeck;
      } else {
        modalRef.componentInstance.cell = cell;
      }

      modalRef.result.then(
        (result) => {
          if (type == 'cash') {
            if (this.game?.cards) {
              this.burntCards = this.game?.cards
                .slice(0, this.cardIndex + 2)
                .reverse();

              var lastCard = this.game?.cards[this.game.cardIndex];
              this.log('last card result: ', lastCard);
              if (lastCard.moves > 0) {
                this.audio.playCheer();
              } else if (lastCard.moves < 0) {
                this.audio.playBoo();
              }
            }
          } else {
            var isSnake = cell.classes?.includes('snakeStart');

            if (isSnake) {
              this.audio.playSlide();
            } else {
              this.audio.playLeaves();
            }
          }
          res('closed');
        },
        (reason) => {
          if (type == 'cash') {
            if (this.game?.cards) {
              this.burntCards = this.game?.cards
                .slice(0, this.cardIndex + 2)
                .reverse();

              var lastCard = this.game?.cards[Math.max(1, this.game.cardIndex)];
              this.log('last card dismiss: ', lastCard);
              if (lastCard.moves > 0) {
                this.audio.playCheer();
              } else if (lastCard.moves < 0) {
                this.audio.playBoo();
              }
            }
          } else {
            var isSnake = cell.classes?.includes('snakeStart');

            if (isSnake) {
              this.audio.playSlide();
            } else {
              this.audio.playLeaves();
            }
          }
          res('dismiss');
        }
      );
    });
  }

  gameUpdateTimeout: any;
  followGame(id: string) {
    if (!id) {
      throw new Error('No id provided');
      return;
    }

    return onValue(
      ref(this.database, 'games/' + id),
      async (snapshot) => {
        this.game = snapshot.val();
        if (!this.game) return;

        this.pieces.forEach((p) => {
          p.player.displayName = this.game?.players.find(
            (a) => a.id == p.player.id
          )?.displayName!;
        });
        this.modal.dismissAll();
        this.gameUpdateTimeout = Date.now();
        var rolled = false;

        if (this.game.lastDice.time > this.lastRoll.time) {
          rolled = true;
          this.lastRoll = this.game.lastDice;
          this.nextRoll.emit(this.game.lastDice.dices[0]);
          this.nextRoll2.emit(this.game.lastDice.dices[1]);
          this.audio.playDice();
        }
        try {
          var actionedPlayer = Object.values(this.game?.actions).find(
            (a) => a.type == 'roll' || a.type != 'move'
          );
          this.activePlayer = this.game.players.find((p) => {
            if (!this.game?.actions) {
              return true;
            }
            return p.id == actionedPlayer?.player;
          });
        } catch (e) {
          if (this.game?.status.status == 'waiting') {
            this.activePlayer = this.game.players[0];
          }
        }

        if (this.game?.status.status == 'waiting') {
          this.openSettings(false);
        }

        if (this.game.status.status == 'deleting') {
          alert('Game is being deleted if a move isnt made soon');
        }

        if (
          this.game.status.currentTick > this.currentTick ||
          !this.game.table
        ) {
          this.currentTick = this.game.status.currentTick;
          this.prompt = '';
          await this.sortTable(rolled);
        }
        await this.checkCardChanges();
        if (this.game.isWon) {
          if (this.game.winnerId == this.firebase.userId) {
            this.audio.playVictory();
          } else {
            this.audio.playGameOver();
          }
          this.modal.dismissAll();
          var component = this.modal.open(VictoryComponent, {
            centered: true,
            size: 'lg',
            backdropClass: 'victory-backdrop',
            windowClass: 'victory-window ',
            modalDialogClass: 'victory-dialog victory-modal',
            backdrop: 'static',
          });
          component.componentInstance.game = this.game;
          component.result.then((result) => {
            this.router.navigate(['lobby/sponsor']);
          });
        }

        this.gameUpdateTimeout = setTimeout(() => {
          this.gameUpdateTimeout = null;
        }, 1000);
      },
      (error) => {
        console.error(error);
      }
    );
  }

  rollTimeout: any; //stops the roll button being clicked more than once every 5 seconds
  async roll(debug: number | null = null) {
    if (this.rollTimeout) {
      return;
    }
    this.rollTimeout = setTimeout(() => {
      this.rollTimeout = null;
    }, 5000);
    const result = await this.firebase.rollDice(this.id, debug);
    if (!result) {
      return;
    }
  }

  start() {
    this.firebase.startGame(this.id);
  }

  async sortTable(rolled: boolean = true) {
    if (this.firstInit) {
      this.ladders = [];
    }

    this.game?.table.forEach((row) => {
      row.forEach((cell) => {
        if (this.firstInit) {
          var adding = false;
          if (cell.ladder) {
            adding = true;
          }

          if (cell.snake) {
            adding = true;
          }
          if (adding) {
            this.ladders.push(cell);
          }
        }

        if (cell.players) {
          for (var player of cell.players) {
            var piece = this.pieces.find((p) => p.id == player);
            if (!piece) {
              piece = {
                id: player,
                player: this.game?.players.find((p) => p.id == player),
                position: cell.id,
                oldPosition: cell.id,
                sL: numberToSL(cell.id),
              };
              try {
                piece.moved = this.game?.actions[player]?.moved;
              } catch (e) {
                piece.moved = false;
              }
              //pieces are 40px by 40px
              //should be centered iin the boxHeight + 12
              var pieceAdjustment = (this.boxHeight + 12) / 2 - 20;
              var boxSize = this.boxHeight + 12;
              piece.left = piece.sL[1] * boxSize + pieceAdjustment + 5 + 'px'; //get left and top positioin
              piece.top = piece.sL[0] * boxSize + pieceAdjustment + 5 + 'px';

              //get left and top positioin
              this.pieces.push(piece);
            } else {
              piece.oldPosition = piece.position;
              piece.position = cell.id;
              try {
                piece.moved = this.game?.actions[player]?.moved || false;
              } catch (e) {}

              piece.sL = numberToSL(cell.id);
            }
          }
        }
      });
    });

    if (this.firstInit) {
      /*
      this.ladder.drawLadders(
        this.ladders,
        this.canvas!,
        this.table!,
        this.ctx!,
        this.boxHeight + 12,
        document
      );
      */
    }

    if (rolled) {
      await this.wait(2200);
    }

    //if last action was a cash and currentPosition !=oldPosition or position then move the piece

    const needsAlert = await this.movePieces(this.pieces);

    if (
      this.game?.actions &&
      this.game?.actions[this.firebase.userId]?.type == 'cash'
    ) {
      var piece = this.pieces.find((p) => p.id == this.firebase.userId);
      if (
        piece &&
        piece.position == piece.oldPosition &&
        piece.currentPosition != piece.oldPosition
      ) {
        this.calculatePiecePosition([piece]);
      }
    }

    if (needsAlert) {
      var current = numberToSL(
        this.pieces.filter((p) => p.moved)[0].currentPosition ||
          this.game?.positions[this.firebase.userId]!
      );
      var cell = this.game?.table[current[0]][current[1]];
      if (!cell) return;
      await this.wait(900);
      var nextAction = Object.entries(this.game?.actions || {})
        .map((key: [string, any]) => {
          key[1].playerId = key[0];
          return key[1];
        })
        .filter((val) => {
          return val.type != 'roll' && val.type != 'move';
        })[0];

      var type = '';
      if (nextAction.type == 'cash') {
        type = 'cash';
      } else if (nextAction.ladderPosition) {
        type = 'ladder';
      } else if (nextAction.snakePosition) {
        type = 'snake';
      }
      //cell might be differnt if not user
      /*
      if (nextAction.player != this.firebase.userId) {
        var playerPos = numberToSL(this.game?.positions[nextAction.player]!);
        if (type == 'ladder') {
          playerPos = numberToSL(nextAction.ladderPosition);
        } else if (type == 'snake') {
          playerPos = numberToSL(nextAction.snakePosition);
        }

        var playerCell = this.game?.table[playerPos[0]][playerPos[1]];
        if (!playerCell) {
          playerCell = cell;
        }
        await this.openAction(playerCell, type);
      } else {
        await this.openAction(cell, type);
      }*/
      await this.openAction(cell, type);
      // are you the user that needed this action?
      if (nextAction.player == this.firebase.userId) {
        await this.firebase.actionDone(
          this.id,
          cell.classes?.includes('cash')
            ? 'cash'
            : cell.snake
            ? 'snake'
            : 'ladder'
        );
      }
    } else {
      this.calculatePiecePosition(this.pieces);
    }

    this.prompt = '';
    this.players = this.game?.players.reduce((acc: any, curr: Player) => {
      acc[curr.id] = curr;
      return acc;
    }, {});
    if (this.game!.status.status == 'waiting') {
      this.prompt += 'Waiting for players to join and to start the game\n';
    }
    if (this.game?.actions) {
      Object.entries(this.game.actions).forEach(async ([key, value]) => {
        if (key == this.firebase.userId) {
          this.prompt = value.display;
          if (value.type == 'roll') {
            this.audio.playTurn();
          }
        } else if (!value.hidePrompt) {
          this.prompt +=
            'Waiting for ' +
            this.game?.players.find((p) => p.id == key)?.displayName +
            ' to ' +
            value.display +
            '\n';
        }
      });
    }
    this.firstInit = false;
  }

  calculatePiecePosition(pieces: any[]) {
    var pieceGroups: any[] = pieces.reduce((acc: any[], curr: any) => {
      var group = acc.find((g: any) => g.position == curr.position);
      if (!group) {
        group = { position: curr.position, pieces: [] };
        acc.push(group);
      }

      group.pieces.push(curr);
      return acc;
    }, []);

    for (let i = 0; i < pieceGroups.length; i++) {
      const group = pieceGroups[i];
      this.calculateGroupPosition(group.pieces);
    }
  }

  calculateGroupPosition(pieces: any[]) {
    var numberOfPieces = pieces.length;
    var patternObj = this.piecePatterns[numberOfPieces - 1];
    var pattern: number[][] = patternObj.pattern;
    var boxSize = this.boxHeight + 12;
    var pieceAdjustment = boxSize / 2 - 20;
    for (let i = 0; i < pieces.length; i++) {
      const piece = pieces[i];

      var left = piece.sL[1] * boxSize + 5; //get left and top positioin
      var top = piece.sL[0] * boxSize + 5;

      if (!patternObj) {
        piece.left = left + pieceAdjustment + 'px';
        piece.top = top + pieceAdjustment + 'px';
        continue;
      }

      var p = pattern[i];

      //calculate left and right, cell width is 100px, pattern at 1 should be 50
      //p[n] is a number between 0 and 2, 1 is centered

      //left is currently the edge of the box, same with top.

      var leftA = p[0] / 2;
      var topA = p[1] / 2;

      left += leftA * boxSize - 20;
      top += topA * boxSize - 20;

      //left += ((p[0] - 1) * boxSize) / 2 - 5;
      // top += ((p[1] - 1) * boxSize) / 2 - 5;
      piece.left = left + 'px';
      piece.top = top + 'px';
    }
  }

  //each x y between 0 and 2, 1 is centered
  piecePatterns = [
    { id: 1, pattern: [[1, 1]] },
    {
      id: 2,
      pattern: [
        [0.5, 1],
        [1.5, 1],
      ],
    },
    {
      id: 3,
      pattern: [
        [1, 0.66],
        [0.66, 1.32],
        [1.32, 1.32],
      ],
    },
    {
      id: 4,
      pattern: [
        [0.5, 0.5],
        [1.5, 0.5],
        [0.5, 1.5],
        [1.5, 1.5],
      ],
    },
    {
      id: 5,
      pattern: [
        [1, 0.5],
        [0.5, 1],
        [1.5, 1],
        [1, 1.5],
        [1, 1],
      ],
    },
  ];

  async movePieces(pieces: any[]) {
    //find which moved

    var hadSnakeOrLadder = false;

    var hasAction = false;

    var actions = Object.values(this.game?.actions || {});
    hasAction =
      actions.filter((a) => a.type != 'roll' && a.type != 'move').length > 0;

    var moved = [];

    try {
      moved = pieces.filter(
        (p) =>
          p.oldPosition != p.position ||
          this.game?.actions[p.id]?.snakePosition == p.position ||
          this.game?.actions[p.id]?.ladderPosition == p.position ||
          p.currentPosition != p.position
      );
    } catch (e) {
      moved = pieces.filter(
        (p) => p.oldPosition != p.position || p.currentPosition != p.position
      );
    }
    //move them
    for (var i = 0; i < moved.length; i++) {
      //is the moved piece from an action?

      var piece = moved[i];
      var lastDice = this.game?.lastDice;
      var dice = 0;
      if (
        lastDice.playerId == piece.id &&
        this.game?.lastDice.time == this.game?.status.lastMove
      ) {
        dice = lastDice.dices[0] + lastDice.dices[1];
      } else if (
        this.game?.actions &&
        Number(this.game?.actions[piece.id]?.moves)
      ) {
        dice = Number(this.game?.actions[piece.id]?.moves);
      } else {
        dice = 0;
      }

      var currentPosition = piece.oldPosition;
      var movingBackwards = false;
      try {
        movingBackwards =
          (this.game?.actions[piece.id] &&
            this.game?.actions[piece.id].movingBackwards) ||
          false;
      } catch (e) {}

      //get previous left and top positions
      var hasMoved = 0;
      var whileLoop =
        dice != 0 ? hasMoved != dice : currentPosition != piece.position;

      while (whileLoop) {
        if (currentPosition > 80) {
          currentPosition = 80;
          return !hadSnakeOrLadder && hasAction;
        } else if (currentPosition < 0) {
          currentPosition = piece.position;
          return !hadSnakeOrLadder && hasAction;
        }
        this.pieces[this.pieces.indexOf(piece)].currentPosition =
          currentPosition;
        var current = numberToSL(currentPosition);

        var left =
          current[1] * (this.boxHeight + 12) +
          5 +
          (this.boxHeight + 12) / 2 -
          20;
        var top =
          current[0] * (this.boxHeight + 12) +
          5 +
          (this.boxHeight + 12) / 2 -
          20;

        piece.left = left + 'px';
        piece.top = top + 'px';

        await this.wait(540);
        //is current position snake or ladder start and is the end posiiton the end of that?
        //var ladder = this.ladders.find((l) => l.id == currentPosition);
        //if at start of snake or ladder, then go down the ladder or up the snake

        /* if (
          ((ladder && ladder.ladder == piece.position) ||
            (ladder && ladder.snake == piece.position)) &&
          this.game?.actions[piece.id]?.isSnakeOrLadder &&
          piece.id == this.firebase.userId
        ) {
          console.log('going up snake/ladder');
          await this.wait(700);
          hadSnakeOrLadder = true;
          var cell = this.game?.table[current[0]][current[1]];
          if (!cell) return;
          //gotta get previous sell where the snake/ladder came from

          var result = await this.openAction(cell, 'action');
          hadSnakeOrLadder = true;
          if (piece.id == this.firebase.userId) {
            await this.firebase.actionDone(
              this.id,
              cell.snake ? 'snake' : 'ladder'
            );
            return;
          }
        }

         //do move forwards or backwards depending on wether theres a snake or ladder
        */
        whileLoop =
          dice != 0 ? hasMoved != dice : currentPosition != piece.position;
        if (!movingBackwards) {
          currentPosition++;
          hasMoved++;
        } else {
          hasMoved--;
          currentPosition--;
        }
      }
    }

    return !hadSnakeOrLadder && hasAction;
  }

  //basic wait
  async wait(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async startedMoving() {
    this.audio.playMove();
  }

  openSettings(isSettings: boolean = true) {
    if (this.modal.activeInstances.length) {
      return;
    }

    const mod = this.modal.open(SettingsComponent, {
      centered: true,
      size: 'lg',
      backdropClass: 'settings-backdrop',
      windowClass: 'victory-window ',
      modalDialogClass: 'victory-dialog victory-modal',
      backdrop: 'static',
    });

    mod.componentInstance.game = this.game;
    mod.componentInstance.isSettings = isSettings;
    mod.componentInstance.playerId = this.firebase.userId;

    mod.result.then(
      (result) => {
        this.settingsClosed = true;
        setTimeout(() => {
          this.settingsClosed = false;
        }, 1000);
        if (!isSettings) {
          this.firebase.startGame(this.id);
        }
      },
      (dismiss) => {
        this.settingsClosed = true;
        setTimeout(() => {
          this.settingsClosed = false;
        }, 1000);
        this.router.navigate(['lobby/sponsor']);
        this.ngOnDestroy();
      }
    );
  }
}
