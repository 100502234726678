<div *ngIf="card" #modalCard id="modalCard" [class]="' modal-card-animation ' + position">


  <div class="card-inner">
    <div class="card-back">
      <img [src]="'assets/cards/card-01.png'">

    </div>

    <div class="card-front">


      <img class="card-text" [src]="'assets/Text/Cash ESG Cards text' + (card.id == 2 ? '' : (card.id -1)) + '.png'">
      <img class="card-moves" [src]="'assets/cards/' + card.asset + '.png'">

    </div>

  </div>




</div>
<div *ngIf="cell" style="text-align: center; font-size: xx-large; position: relative; display: flex;
align-items: center; justify-content: center; flex-direction: column;
    height: 100%; padding: 20px;">
  <div style="flex-grow: 1;"></div>
  <div> {{cell.text}}</div>
  <div style="flex-grow: 1;"></div>

</div>
<button (click)="close()" class="close-button animateInLonger"
        style="display: flex; flex-direction: row; align-items: center; justify-content: space-between;"
        *ngIf="position == 'center' || cell"><i class="fas fa-times"></i>
  <div style="margin: 0px 10px;">Close</div>
</button>
