<div>
  Pick up a card if you land on a green square. If you then move and land on another green square, or on a snake or
  ladder, pick up another and/move up or down the snake or ladder (oil slicks and flowers).

</div>

<div>Green cards have a topic, e.g. 'Energy' or 'Central Bank', and a statement about that topic. The resulting
  instruction
  to move reflects how much the statement positively enhances the environment.</div>

<div>More than one piece may occupy a square.</div>

<div>At the end, there is no need to roll the exact number of moves to finish!</div>
<img class="side-logo" src="assets/logo2-cropped.png">
