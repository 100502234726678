import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GameStatus } from 'shared';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
})
export class PasswordComponent implements OnInit {
  @Input('game') game?: GameStatus;
  password: string = '';
  constructor(public modal: NgbActiveModal) {}

  ngOnInit(): void {}

  close() {
    this.modal.close(this.password);
  }

  dismiss() {
    this.modal.dismiss();
  }
}
