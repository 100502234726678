import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Card, Cell } from 'shared';
import { SoundsService } from '../../Services/sounds.service';
import { end } from '@popperjs/core';

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss'],
})
export class ActionComponent implements OnInit {
  @Inject('cell') cell?: Cell;
  @Inject('card') card?: Card;
  @Inject('startDiv') startDiv?: HTMLDivElement;
  @Inject('endDiv') endDiv?: HTMLDivElement;
  @Inject('parentEndDiv') parentEndDiv?: boolean = false;
  @ViewChild('modalCard') modalCard?: ElementRef<HTMLDivElement>;
  position = 'before';

  constructor(
    public modal: NgbActiveModal,
    private renderer: Renderer2,
    public audio: SoundsService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    // get onscreen coordinates of the startDiv
    setTimeout(() => {
      if (this.card) {
        this.moveTheCard();
      }
    }, 0);
  }

  async moveTheCard() {
    const startDivRect = this.startDiv?.getBoundingClientRect();

    // move modalCard so it directly overlaps startDivRect

    //get startdiv transform

    if (this.startDiv) {
      const startDivTransform = window.getComputedStyle(
        this.startDiv
      ).transform;

      const startDivTop = window.getComputedStyle(this.startDiv).top;
      const startDivLeft = window.getComputedStyle(this.startDiv).left;

      //get z rotation
      var [a, b, c, d, tx, ty] = [0, 0, 0, 0, 0, 0];

      try {
        [a, b, c, d, tx, ty] = startDivTransform
          .split('(')[1]
          .split(')')[0]
          .split(',')
          .map(parseFloat);
      } catch (e) {}
      const xRotation = Math.atan2(b, a);
      const yRotation = Math.atan2(c, d);

      // Calculate Z rotation (same as X or Y rotation)
      const zRotation = xRotation;

      const xRotationDegrees = 0;
      const yRotationDegrees = 0;
      const zRotationDegrees = (zRotation * 180) / Math.PI;

      //set to modalCard
      this.renderer.setStyle(
        this.modalCard?.nativeElement.children[0],
        'transform',
        'rotateX(' +
          xRotationDegrees +
          'deg) rotateY(' +
          yRotationDegrees +
          'deg) rotateZ(' +
          zRotationDegrees +
          'deg)' +
          ' scale(1)'
      );

      var top: number =
        Number(startDivRect?.top) + Number(startDivTop.split('px')[0]) + 15;
      var left: number =
        Number(startDivRect?.left) + Number(startDivLeft.split('px')[0]) + 150;

      this.renderer.setStyle(this.modalCard?.nativeElement, 'top', `${top}px`);
      this.renderer.setStyle(
        this.modalCard?.nativeElement,
        'left',
        `${left}px`
      );
    } else {
      this.renderer.setStyle(
        this.modalCard?.nativeElement,
        'top',
        `${startDivRect?.top}px`
      );
      this.renderer.setStyle(
        this.modalCard?.nativeElement,
        'left',
        `${startDivRect?.left}px`
      );
    }

    this.position = 'starting';

    await this.waitN(50);
    //random number between 0 and 3
    const random: number = Math.floor(Math.random() * 5);
    var transform: string = '';
    if (random === 0) {
      transform = 'rotateX(0deg) rotateY(180deg) rotateZ(0deg)';
    } else if (random === 1) {
      transform = 'rotateX(0deg) rotateY(180deg) rotateZ(360deg)';
    } else if (random === 2) {
      transform = 'rotateX(360deg) rotateY(180deg) rotateZ(0deg)';
    } else if (random === 3) {
      transform = 'rotateX(360deg) rotateY(180deg) rotateZ(360deg)';
    } else if (random === 4) {
      transform = 'rotateX(0deg) rotateY(180deg) rotateZ(1080deg)';
    }

    transform += ' scale(1.5)';

    //card has a div with class card-inner, run transform: rotateY(180deg);

    this.renderer.setStyle(
      this.modalCard?.nativeElement.children[0],
      'transform',
      transform
    );

    //also make 1.5 bigger

    //move the card to the center of screen
    this.renderer.setStyle(
      this.modalCard?.nativeElement,
      'top',
      `${window.innerHeight / 2 - 200}px`
    );
    this.renderer.setStyle(
      this.modalCard?.nativeElement,
      'left',
      `${window.innerWidth / 2 - 100}px`
    );

    //give a big blurry box shadow
    this.renderer.setStyle(
      this.modalCard?.nativeElement.children[0],
      'box-shadow',
      '0 0 500px 100px rgba(0, 0, 0, 0.5)'
    );

    await this.waitN(1000);
    this.position = 'center';
    await this.waitN(200);
    //this.close();
    return;
    //make it back to orgiginal size
  }

  async close() {
    if (this.card) {
      this.audio.playCard();
      const endDivRect = this.endDiv?.getBoundingClientRect();
      var finalTransform =
        'rotateX(0deg) rotateY(180deg) rotateZ(0deg) scale(1)';
      if (this.endDiv) {
        const endDivTop = window.getComputedStyle(this.endDiv).top;
        const endDivLeft = window.getComputedStyle(this.endDiv).left;
        var top: number =
          Number(endDivRect?.top) + Number(endDivTop.split('px')[0]) + 13;
        var left: number =
          Number(endDivRect?.left) +
          Number(endDivLeft.split('px')[0]) +
          152 * (this.parentEndDiv ? -1 : 1);

        this.renderer.setStyle(
          this.modalCard?.nativeElement,
          'top',
          `${top}px`
        );
        this.renderer.setStyle(
          this.modalCard?.nativeElement,
          'left',
          `${left}px`
        );

        var endDivTransform = window.getComputedStyle(this.endDiv).transform;
        var [a, b, c, d, tx, ty] = [0, 0, 0, 0, 0, 0];
        try {
          [a, b, c, d, tx, ty] = endDivTransform
            .split('(')[1]
            .split(')')[0]
            .split(',')
            .map(parseFloat);
        } catch (e) {}

        const xRotation = Math.atan2(b, a);
        const yRotation = Math.atan2(c, d);

        // Calculate Z rotation (same as X or Y rotation)
        const zRotation = xRotation;

        const xRotationDegrees = 0;
        const yRotationDegrees = 180;
        const zRotationDegrees = (zRotation * 180) / Math.PI;

        finalTransform =
          'rotateX(' +
          xRotationDegrees +
          'deg) rotateY(' +
          yRotationDegrees +
          'deg) rotateZ(' +
          zRotationDegrees +
          'deg)' +
          ' scale(1)';

        this.renderer.setStyle(
          this.modalCard?.nativeElement.children[0],
          'transform',
          finalTransform
        );
      } else {
        this.renderer.setStyle(
          this.modalCard?.nativeElement,
          'top',
          `${endDivRect?.top}px`
        );
        this.renderer.setStyle(
          this.modalCard?.nativeElement,
          'left',
          `${endDivRect?.left}px`
        );

        var finalTransform =
          'rotateX(0deg) rotateY(180deg) rotateZ(0deg) scale(1)';

        this.renderer.setStyle(
          this.modalCard?.nativeElement.children[0],
          'transform',
          finalTransform
        );
      }
      //remove the box shadow
      this.renderer.setStyle(
        this.modalCard?.nativeElement.children[0],
        'box-shadow',
        'none'
      );
      this.position = 'ending';
      await this.waitN(800);
    }

    this.modal.close();
  }

  async waitN(n: number) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(n);
      }, n);
    });
  }
}
