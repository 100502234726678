import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { foo, Game, GameStatus } from 'shared';
import { FirebaseService } from '../../firebase.service';
import { filter } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PasswordComponent } from '../../Models/password/password.component';
import {
  uniqueNamesGenerator,
  Config,
  adjectives,
  colors,
  animals,
} from 'unique-names-generator';
import { JoinGameComponent } from '../../Modals/join-game/join-game.component';

@Component({
  selector: 'app-lobby',
  templateUrl: './lobby.component.html',
  styleUrls: ['./lobby.component.scss'],
})
export class LobbyComponent implements OnInit {
  displayName: string = '';
  serverId: string = '';
  games: GameStatus[] = [];
  subs: any[] = [];
  joinExpanded: boolean = false;

  currentRoute: string = 'about';
  routes: { path: string; name: string }[] = [
    { path: 'about', name: 'ABOUT' },
    { path: 'howto', name: 'RULES' },
    { path: 'sponsor', name: 'SPONSORS' },
    { path: 'cards', name: 'CARDS' },
    { path: 'contact', name: 'CONTACTS' },
  ];
  gameSettings: any = {
    privateGame: false,
    maxPlayers: 4,
    password: '',
  };
  sliderPosition: number = 0; // Init

  constructor(
    public firebase: FirebaseService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modal: NgbModal
  ) {}
  ngOnInit() {
    if (localStorage.getItem('displayName')) {
      this.displayName = localStorage.getItem('displayName')!;
    } else {
      this.displayName = this.generateRandomName();
    }
    this.followGames();
    this.currentRoute =
      this.activatedRoute.firstChild?.snapshot.url[0]?.path || '';
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.currentRoute =
          this.activatedRoute.firstChild?.snapshot.url[0]?.path || '';
      });
  }

  ngOnDestroy() {
    this.subs.forEach((s) => {
      try {
        s.unsubscribe();
      } catch (e) {
        //cant unsubscribe so its not subscribed so good
        try {
          s();
        } catch (e) {}
      }
    });
  }

  ngAfterViewInit() {
    console.log('Screen Size: ', window.innerWidth, window.innerHeight);
    setTimeout(() => {
      this.updateSliderValue(true, true);
    }, 0);
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: Event): void {
    this.updateSliderValue(false, true);
  }

  async create(singlePlayer: boolean = true) {
    if (!this.displayName) {
      alert('Please choose a username');
      return;
    }
    localStorage.setItem('displayName', this.displayName);

    this.gameSettings.maxPlayers = singlePlayer
      ? 1
      : this.gameSettings.maxPlayers;
    const game: Game = await this.firebase
      .createGame(this.displayName, this.gameSettings)
      .then((a) => a)
      .catch((e) => {
        console.error(e);
        alert('Could not create game');
        return null;
      });

    if (!game) {
      alert('no game');
    }

    this.router.navigate(['/game'], { queryParams: { id: game.id } });
  }

  togglePrivate() {}

  async openMultiplayer() {
    var modal = this.modal.open(JoinGameComponent, {
      centered: true,
      size: 'lg',
      backdropClass: 'settings-backdrop',
      windowClass: 'victory-window ',
      modalDialogClass: 'victory-dialog victory-modal',
      backdrop: true,
    });
    modal.componentInstance.games = this.games;
    var res = await modal.result
      .then((a) => {
        switch (a.type) {
          case 'join':
            this.serverId = a.id;
            this.join();
            break;
          case 'create':
            this.create(false);
            break;
          case 'spectate':
            this.router.navigate(['/game'], { queryParams: { id: a.id } });
            break;
        }
      })
      .catch((e) => null);
  }

  async join() {
    if (!this.serverId) {
      console.log('no server id');
      return;
    }

    if (!this.displayName) {
      alert('Please choose a username');
      return;
    }
    localStorage.setItem('displayName', this.displayName);

    var g = this.games.find((g) => g.id == this.serverId);
    if (!g) {
      //coultn find game
      alert('Could not find game');
      return;
    }

    if (g.players >= g.gameSettings.maxPlayers || g.status != 'waiting') {
      alert('Game is full or has already started, entering spectator mode');
      this.router.navigate(['/game'], { queryParams: { id: g.id } });
      return;
    }
    var pass = '';
    if (g.gameSettings.privateGame) {
      var dialogClass = 'modal-dialog action-modal';

      const mod = this.modal.open(PasswordComponent, {
        centered: true,
        size: 'lg',
        backdropClass: 'modal-backdrop',
        windowClass: 'modal-window ',
        modalDialogClass: dialogClass,
        backdrop: 'static',
      });
      mod.componentInstance.game = g;
      var res = await mod.result.then(
        (res) => {
          if (res != g?.gameSettings.password) {
            alert('incorrect password!');
            return false;
          }
          pass = res;
          return true;
        },
        (err) => {
          return false;
        }
      );
      if (!res) {
        return;
      }
    }

    const game: Game = await this.firebase
      .joinGame(this.serverId, this.displayName, pass)
      .then((a) => a)
      .catch((e) => {
        console.error(e);
        alert('Could not join game ');
        return null;
      });

    if (!game) {
      alert('no game');
      return;
    }

    this.router.navigate(['/game'], { queryParams: { id: game.id } });
  }

  async followGames() {
    const unsub = this.firebase.getGames();
    this.subs.push(unsub);
    this.subs.push(
      this.firebase.followGames().subscribe((games) => {
        this.games = games
          .filter((a) => a.status == 'waiting' || a.status == 'playing')
          .sort((a, b) => {
            if (a.status == 'waiting' && b.status == 'playing') return -1;
            if (a.status == 'playing' && b.status == 'waiting') return 1;
            return 0;
          });
      })
    );
  }

  updateSliderValue(event: boolean = true, resized: boolean = false) {
    const sliderContainer = document.querySelector(
      '.slider-container'
    ) as HTMLElement;
    const slider = document.querySelector('.slider') as HTMLInputElement;
    const sliderValue = document.querySelector('.slider-value') as HTMLElement;

    if (slider && sliderValue) {
      const sliderWidth = slider.clientWidth;
      const sliderMax = 5; // Adjusted maximum value

      const sliderValuePercentage =
        ((this.gameSettings.maxPlayers - 1) / sliderMax) * 100;

      const thumbWidth =
        slider.querySelector('.slider::-webkit-slider-thumb')?.clientWidth ||
        25; // Get the width of the thumb

      let position =
        (sliderWidth * sliderValuePercentage) / 100 -
        thumbWidth * 1.5 * (sliderValuePercentage / 100); // Calculate the position of the thumb

      this.sliderPosition = position;

      //put a dot at each tick

      //remove all from sliderContainer with class dot

      const dots = document.querySelectorAll('.dot');
      dots.forEach((dot) => {
        dot.remove();
      });
      for (var i = 0; i <= sliderMax; i++) {
        if (i == this.gameSettings.maxPlayers - 1) continue;
        const sliderValuePercentage2 = (i / sliderMax) * 100;
        const dot = document.createElement('div');
        dot.classList.add('dot');

        var position2 =
          (sliderWidth * sliderValuePercentage2) / 100 -
          thumbWidth * 1.5 * (sliderValuePercentage2 / 100);

        position2 += thumbWidth / 2 + 1.5;
        dot.style.left = `${position2}px`;
        sliderContainer.appendChild(dot);
      }
    }
  }
  customConfig: Config = {
    dictionaries: [adjectives, colors],
    separator: '-',
    length: 2,
  };
  generateRandomName() {
    return uniqueNamesGenerator({
      dictionaries: [adjectives, animals],
    })
      .split('_')
      .map((s: string) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  }
}
