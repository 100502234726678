<div [id]="'cube-' + randomId" class="cube" style="margin-top: 100px;">
  <div class="face -front side">
    <div class="dot one-1"></div>
  </div>
  <div class="face -left side">

    <div class="dot six-1"></div>
    <div class="dot six-2"></div>
    <div class="dot six-3"></div>
    <div class="dot six-4"></div>
    <div class="dot six-5"></div>
    <div class="dot six-6"></div>
  </div>
  <div class="face -top side">


    <div class="dot four-1"></div>
    <div class="dot four-2"></div>
    <div class="dot four-3"></div>
    <div class="dot four-4"></div>
  </div>
  <div class="face -bottom side">
    <div class="dot five-1"></div>
    <div class="dot five-2"></div>
    <div class="dot five-3"></div>
    <div class="dot five-4"></div>
    <div class="dot five-5"></div>
  </div>
  <div class="face -right side">
    <div class="dot three-1"></div>
    <div class="dot three-2"></div>
    <div class="dot three-3"></div>
  </div>
  <div class="face -back side">
    <div class="dot two-1"></div>
    <div class="dot two-2"></div>
  </div>
</div>
