import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shorternName',
})
export class ShorternNamePipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    if (!String(value)) {
      return null;
    }
    const name = String(value)
      .split(' ')
      .map((a) => a[0].toLocaleUpperCase())
      .join('');
    return name.substring(0, 2);
  }
}
