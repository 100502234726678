import { Injectable } from '@angular/core';

import { Cell, numberToSL } from 'shared';

@Injectable({
  providedIn: 'root',
})
export class LadderService {
  ctx?: CanvasRenderingContext2D;
  canvas?: HTMLCanvasElement;
  table?: HTMLDivElement;
  ladderPositions: { [startD: number]: { x: number; y: number } } = {};

  cellHeight: number = 100;

  pipeImage: HTMLImageElement = new Image();
  vineImage: HTMLImageElement = new Image();

  oilDropImage: HTMLImageElement = new Image();
  document: Document = document;

  pipesAndVinesNames: string[] = [
    '22-04.png',
    '12-26.png',
    '16-33.png',
    '18-15.png',
    '23-58.png',
    '42-62.png',
    '44-30.png',
    '51-15.png',
    '60-67.png',
    '63-46.png',
    '65-80.png',
    '66-49.png',
    '71-52.png',
    '75-37.png',
  ];

  pipesAndVines: { image: HTMLImageElement; name: string }[] = [];

  promises: Promise<any>[] = [];
  constructor() {
    this.pipeImage.src = 'assets/pipe-section.png';

    this.vineImage.src = 'assets/vine-cartoon.png';
    this.oilDropImage.src = 'assets/oil-drop.png';

    for (let i = 0; i < this.pipesAndVinesNames.length; i++) {
      new Promise((res, rej) => {
        var name = this.pipesAndVinesNames[i].split('.')[0];
        var image = new Image();
        image.src = 'assets/vines/' + this.pipesAndVinesNames[i];
        this.pipesAndVines.push({ image: image, name: name });
        image.onload = () => {
          res(true);
        };
      });
    }

    this.promises.push(
      new Promise((res, rej) => {
        this.pipeImage.onload = () => {
          res(true);
        };
      })
    );

    this.promises.push();

    this.promises.push(
      new Promise((res, rej) => {
        this.oilDropImage.onload = () => {
          res(true);
        };
      })
    );
  }

  async drawLadders(
    ladders: Cell[],
    canvas: HTMLCanvasElement,
    table: HTMLDivElement,
    ctx: CanvasRenderingContext2D,
    cellHeight: number,
    document: Document
  ) {
    this.canvas = canvas;
    this.table = table;
    this.ctx = ctx;
    this.cellHeight = cellHeight;
    if (!this.ctx) {
      console.log('Invalid canvas context');
      return;
    }

    await Promise.all(this.promises);

    this.document = document;
    this.ctx!.clearRect(0, 0, this.canvas.width, this.canvas.height);

    this.ctx.save();
    this.canvas.width = this.table.clientWidth;
    this.canvas.height = this.table.clientHeight;

    ladders.forEach((ladder) => {
      if (ladder.ladder) {
        this.drawLadder(ladder.id, ladder.ladder!, true);
      }

      if (ladder.snake) {
        this.drawLadder(ladder.id, ladder.snake!, false);
      }
    });
    this.ctx.save();

    const a = document.createElement('a');
    a.href = this.canvas.toDataURL();
    a.download = 'ladders.png';
    // a.click();
  }

  drawLadderFromAssets(startD: number, endD: number, isLadder: boolean) {
    if (isNaN(startD) || isNaN(endD) || !this.ctx) {
      console.log('Invalid ladder: ' + isNaN(startD) + ' ' + isNaN(endD));
      return;
    }
    // this.ctx.imageSmoothingEnabled = false;
    // this.ctx.lineWidth = 20;
    // Calculate ladder positions
    const start = numberToSL(Number(startD));
    const end = numberToSL(Number(endD));

    const cellWidth = this.cellHeight;
    const cellHeight = this.cellHeight;

    var cellWidthAdjustment = cellWidth / 2;
    var startX = start[1] * cellWidth + cellWidthAdjustment;
    var startY = start[0] * cellHeight + cellWidthAdjustment;

    var endX = end[1] * cellWidth + cellWidthAdjustment;
    var endY = end[0] * cellHeight + cellWidthAdjustment;

    var topLeft = [0, 0];

    if (startX < endX) {
      topLeft[0] = startX;
    } else {
      topLeft[0] = endX;
    }

    if (startY < endY) {
      topLeft[1] = startY;
    } else {
      topLeft[1] = endY;
    }

    var bottomRight = [0, 0];

    if (startX > endX) {
      bottomRight[0] = startX;
    } else {
      bottomRight[0] = endX;
    }

    if (startY > endY) {
      bottomRight[1] = startY;
    } else {
      bottomRight[1] = endY;
    }

    // Create a temporary canvas
    const tempCanvas = document.createElement('canvas');
    const tempCtx = tempCanvas.getContext('2d');

    if (!tempCtx) {
      console.log('Invalid temp canvas context');
      return;
    }

    // Draw the pipe image by repeating it horizontally
    console.log('\n');
    console.log(
      'drawing' +
        (isLadder ? ' vine ' : ' slick ') +
        'cell ' +
        startD +
        ' to ' +
        endD
    );
    console.log(
      '(startX, startY) -> (endX, endY) = (' +
        startX +
        ', ' +
        startY +
        ') -> (' +
        endX +
        ', ' +
        endY +
        ')'
    );

    var img = this.pipesAndVines.find((a) => a.name == startD + '-' + endD);
    if (!img || img == undefined || !img.image) {
      console.error('Invalid image: ' + startD + '-' + endD);
      return;
    }

    this.ctx.save();

    console.log('Top Left: ', topLeft);
    console.log('Bottom Right: ', bottomRight);
    console.log(
      'Width,Height=' +
        Math.abs(bottomRight[0] - topLeft[0]) +
        'px, ' +
        Math.abs(topLeft[1] - bottomRight[1]) +
        'px'
    );

    this.ctx.drawImage(
      img.image,
      topLeft[0],
      topLeft[1],
      Math.abs(bottomRight[0] - topLeft[0]),
      Math.abs(topLeft[1] - bottomRight[1])
    );

    // Restore the canvas state
    this.ctx.restore();
  }

  drawLadder(startD: number, endD: number, isLadder: boolean) {
    if (isNaN(startD) || isNaN(endD) || !this.ctx) {
      console.log('Invalid ladder: ' + isNaN(startD) + ' ' + isNaN(endD));
      return;
    }

    // this.ctx.imageSmoothingEnabled = false;
    // this.ctx.lineWidth = 20;

    // Calculate ladder positions
    const start = numberToSL(Number(startD));
    const end = numberToSL(Number(endD));

    const cellWidth = this.cellHeight;
    const cellHeight = this.cellHeight;

    var startX = start[0] * cellWidth + cellWidth / 2;
    var startY = start[1] * cellHeight + cellHeight / 2;

    var endX = end[0] * cellWidth + cellWidth / 2;
    var endY = end[1] * cellHeight + cellHeight / 2;

    // Adjust the start and end coordinates to make the pipe image look like it's connecting the cells
    const [startAdjustMents, endAdjustMents] = this.calculateAdjustments(
      [startX, startY],
      [endX, endY]
    );
    startX += startAdjustMents[0];
    startY += startAdjustMents[1];
    endX += endAdjustMents[0];
    endY += endAdjustMents[1];

    startX = Math.round(startX);
    startY = Math.round(startY);
    endX = Math.round(endX);
    endY = Math.round(endY);

    // Angle rotate -90 degrees
    const aspectRatio = Math.round(694 / 110);

    // Calculate the width and height of the pipe image based on the start and end coordinates
    const pipeWidth = Math.round(
      Math.sqrt((endX - startX) ** 2 + (endY - startY) ** 2)
    );
    const pipeHeight = 20; // Fixed height
    //console.log(pipeWidth);

    // Calculate the angle of rotation
    // Calculate the angle of rotation to align with the northeast direction
    //console.log(endX - startX, startY - endY);
    var angle = -Math.atan2(endX - startX, startY - endY);

    var degress = (angle * 180) / Math.PI;
    degress = Math.round(degress);

    angle = (degress * Math.PI) / 180;

    const centerY = Math.round((startX + endX) / 2);
    const centerX = Math.round((startY + endY) / 2);

    // Create a temporary canvas
    const tempCanvas = document.createElement('canvas');
    const tempCtx = tempCanvas.getContext('2d');
    const canvasHeight = this.canvas?.width || 900;

    if (!tempCtx) {
      console.log('Invalid temp canvas context');
      return;
    }

    // Set the fixed height of the temporary canvas
    tempCanvas.height = 20;
    // Calculate the width of the temporary canvas based on the pipe's width
    tempCanvas.width = pipeWidth;

    // Draw the pipe image by repeating it horizontally

    for (let i = 0; i < pipeWidth; i += pipeHeight * aspectRatio) {
      tempCtx.drawImage(
        !isLadder ? this.pipeImage : this.vineImage, //image
        0, //sx
        0, //sy
        this.pipeImage.width, //swidth
        this.pipeImage.height, // sHeight Use canvasHeight instead of pipeHeight
        i, //dx
        0, //dy
        pipeHeight * aspectRatio, //dWidth
        20 //dHeight Use canvasHeight instead of pipeHeight
      );
    }
    //downlaod the image
    var image = new Image();
    image.src = tempCanvas.toDataURL();
    //var w = window.open('');
    //if (w) w.document.write(image.outerHTML);
    // Save the current canvas state
    this.ctx.save();

    // Translate to the starting position
    this.ctx.translate(Math.round(centerX), Math.round(centerY));

    // Rotate the canvas to match the angle

    this.ctx.rotate(angle);

    // Draw the contents of the temporary canvas on the main canvas, positioned at (0, 0)
    this.ctx.globalAlpha = isLadder ? 0.65 : 0.95;
    this.ctx.drawImage(
      tempCanvas,
      0,
      0,
      pipeWidth,
      pipeHeight,
      -pipeWidth / 2,
      -pipeHeight / 2,
      pipeWidth,
      pipeHeight
    );

    // Restore the canvas state
    this.ctx.restore();

    // at the end of a ladder, put the oil.png from assets
    if (!isLadder) {
      // Calculate the position for the oil drop based on the original end[0] and end[1] coordinates
      this.ctx.globalAlpha = 0.6;
      this.ctx.drawImage(this.oilDropImage, endY - 15, endX, 30, 50);
    }

    this.ladderPositions[startD] = { x: endX, y: endY };
  }

  calculateAdjustments([startX, startY]: number[], [endX, endY]: number[]) {
    var direction = 0; //0 is North, 1 is NorthEast, 2 is East, 3 is SouthEast, 4 is South, 5 is SouthWest, 6 is West, 7 is NorthWest
    if (startX == endX && startY < endY) {
      //North
      direction = 0;
    } else if (startX > endX && startY < endY) {
      //NorthEast
      direction = 1;
    } else if (startX == endX && startY < endY) {
      //East
      direction = 2;
    } else if (startX < endX && startY < endY) {
      //SouthEast
      direction = 3;
    } else if (startX < endX && startY == endY) {
      //South
      direction = 4;
    } else if (startX < endX && startY > endY) {
      //SouthWest
      direction = 5;
    } else if (startX == endX && startY > endY) {
      //West
      direction = 6;
    } else if (startX > endX && startY > endY) {
      //NorthWest
      direction = 7;
    }

    var startAdjustMents = [0, 0];
    var endAdjustMents = [0, 0];

    const quarter = this.cellHeight / 4;

    switch (direction) {
      case 0: //North
        startAdjustMents = [-quarter, 0];
        endAdjustMents = [quarter, 0];
        break;
      case 1: //NorthEast
        startAdjustMents = [-quarter, quarter];
        endAdjustMents = [quarter, -quarter];
        break;
      case 2: //East
        startAdjustMents = [0, quarter];
        endAdjustMents = [0, -quarter];
        break;
      case 3: //SouthEast
        startAdjustMents = [quarter, quarter];
        endAdjustMents = [-quarter, -quarter];
        break;
      case 4: //South
        startAdjustMents = [quarter, 0];
        endAdjustMents = [-quarter, 0];
        break;
      case 5: //SouthWest
        startAdjustMents = [quarter, -quarter];
        endAdjustMents = [-quarter, quarter];
        break;
      case 6: //West
        startAdjustMents = [0, -quarter];
        endAdjustMents = [0, quarter];
        break;
      case 7: //NorthWest
        startAdjustMents = [-quarter, -quarter];
        endAdjustMents = [quarter, quarter];
        break;
    }
    return [startAdjustMents, endAdjustMents];
  }
}
